.content {
  display: flex;
  min-height: calc(100vh - 122px);
  max-width: calc(100vw - 0.1%);
  .css-79elbk {
    position: unset;
  }
  aside {
    border-right: 1px solid #ecf1f4;
    position: fixed;
    margin-top: 100px;
    min-height: 100vh;
    background-color: #fafafa;
  }
  .main {
    margin: 90px 16px 16px 260px;
    padding: 16px;
    width: 100%;
    overflow-x: hidden;
    border-radius: 8px;
  }
  .main_white_bg {
    background-color: #fff;
    border: 1px solid #ecf1f4;
    .container {
      padding: 16px;
    }
  }
  .main-no-menu {
    margin: 70px 0px 16px 0px;
    width: 100%;
    overflow-x: hidden;
  }
  .collapsed-main {
    margin: 90px 16px 16px 80px;
  }
  .fixed-header-main {
    margin-top: 195px !important;
  }
  &.has-banner {
    aside {
      margin-top: 140px;
    }
    .main {
      margin-top: 130px;
    }
    .main-no-menu {
      margin-top: 110px;
    }
    .fixed-header-main {
      margin-top: 235px !important;
    }
  }
  @media only screen and (max-width: 992px) {
    aside {
      display: none;
    }
    .main {
      margin: 70px 0 0 0;
      h2 {
        font-size: 24px;
        line-height: 1;
      }
      .container {
        .row {
          flex-direction: column;
          .alooba-input,
          .dropdown_select {
            width: 100%;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1490px) {
    &.has-banner {
      aside {
        margin-top: 180px;
      }
      .main {
        margin-top: 170px;
      }
      .main-no-menu {
        margin-top: 150px;
      }
      .fixed-header-main {
        margin-top: 275px !important;
      }
    }
  }
}
