@import '../../../styles/colors.scss';
.additional-credit-packages {
    .packages {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
        margin-top: 20px;
        flex-direction: row;
        column-gap: 40px;

        @media (max-width: 1023px) {
            gap: 20px;
        }
    }
    .credit-bundle-card {
        border: 1px solid #DEDFE1;
        border-radius: 4px;
        padding: 16px;
        margin-bottom: 20px;
        background-color: white;
        text-align: center;
        width: calc(32% - 60px);
        @media (max-width: 1023px) {
            width: calc(50% - 20px);
        }
        @media (max-width: 767px) {
            width: 100%;
        }
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    
        h3.credits {
            font-size: 18px;
            font-weight: 700;
            color: $dark;
            margin-bottom: 12px;
        }
    
        p {
            font-size: 14px;
            margin: 8px 0;
        }
    
        p.savings {
            font-weight: 600;
            color: $success;
    
            strong {
                font-weight: bold;
            }
        }
    
        .buy-now {
            margin-top: auto;
            button {
                width: 100%;
            }
        }
    }
    
    @media (max-width: 1023px) {
        .credit-bundle-card {
            padding: 12px;
    
            h3.credits {
                font-size: 16px;
            }
    
            p {
                font-size: 13px;
            }
    
            .buy-now {
                margin-top: 16px;
            }
        }
    }
}
