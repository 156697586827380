.multiselect {
  .card {
    background: #fff;
    padding: 8px;
    border: 1px solid #ECF1F4;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  .inline {
    display: inline-block;
    .css-10nd86i {
      top: 4px;
    }
    .add_button {
      margin-top: 0px;
      margin-bottom: 8px;
    }
  }
}