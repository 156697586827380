@import '../../styles/colors.scss';

$orange: #f68c3e;
$blue: #004385;

.auth-wrapper {
  display: flex;
  height: 100%;
  min-height: 100vh;
  .left {
    width: 55%;
    padding: 130px 0 130px 16px;
    background: #fafafa;
    display: flex;
    justify-content: center;
    .intro, img {
      max-width: 500px;
    }
  }
  .right {
    min-width: 45%;
    background: #fff;
    padding: 100px 32px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    &>div {
      min-width: 480px;
    }
    h2 {
      color: $blue;
    }
    p {
      a {
        color: $orange;
        text-decoration: none;
      }
    }
  }
  #login_page {
    #divider {
      margin-top: 32px;
    }
    #social_login {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        width: calc(50% - 12px);
        height: 42px;
        div {
          height: 0px;
          display: flex;
          align-items: center;
        }
        img {
          max-width: 180px;
          min-width: 100%;
        }
      }
    }
    .auth-error {
      margin-top: 20px;
      display: block;
      text-align: center;
      color: red;
      font-weight: bold;
    }
    .sso-active-directory {
      margin-top: 10px;
      padding: 10px;
      display: flex;
      justify-content: center;
      background: #ffffff;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px;
      border-radius: 5px;
      cursor: pointer;
      color: $dark;
      img {
        margin-right: 8px;
      }
      &:hover {
        background: $hover;
        text-decoration: none;
      }
      &.sso-active-directory {
        img {
          height: 24px;
        }
        font-weight: bold;
      }
    }
    .username-display {
      margin-top: 18px;
      display: flex;
      font-weight: bold;
      img {
        margin-right: 5px;
        width: 24px;
      }
      .name {
        display: flex;
        align-items: center;
      }
      a {
        margin-left: 10px;
        margin-top: 3px;
      }
    }
  }
  .action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      color: #004385;
      text-decoration: none;
      float: left;
    }
    button {
      float: right;
    }
  }

  @media only screen and (max-width: 992px) {
    .left {
      display: none;
    }
    .right {
      width: 100%;
      padding: 40px 0;
      min-width: unset;
      img {
        width: 120px;
      }
      h2 {
        margin-top: 20px;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .right {
      &>div {
        min-width: 90%;
      }
    }
  }

  .alert {
    margin-top: 20px;
    text-align: center;
    color: #fff;
    background-color: rgba($orange, 0.9);
    padding: 20px;
    border-radius: 5px;
    font-weight: bold;
    max-width: 440px;
  }
}