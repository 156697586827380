@import '../../styles/colors.scss';
@import 'styles/responsiveness';
@mixin flex-display {
  display: flex;
  align-items: center;
}

.stat-icon,
.copy-field {
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-right: 20px;
  }
  h5 {
    padding: 0 8px;
  }
  p {
    min-width: fit-content;
  }
}
.action-menu-wrapper .stat-icon {
  margin-right: 5px;
}
.copy-field {
  min-height: 24px;
}
.section-title {
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: $dark;
  margin-bottom: 14px;

  button.right {
    margin-top: -4px;
  }
}

.assessment-container {
  .tab-container {
    position: fixed;
    top: 145px;
    z-index: 19;
    button {
      box-shadow: none;
    }
  }
  &.has-banner {
    .tab-container {
      top: 185px;
      @media only screen and (max-width: 1490px) {
        top: 225px;
      }
    }
  }
}

.no-access-container {
  margin-top: 40px;
  padding: 16px;
  font-family: "NunitoSans-Regular", sans-serif; 
  font-size: 16px;
  color: #343333;
  text-align: center;
}

.no-access-title {
  margin-right: auto;
  margin-left: auto;
  font-size: 30px !important;
  color: #25262e !important;
  font-family: Work Sans, sans-serif;
  line-height: 40px !important;
  text-align: center;
}

.no-access-text {
  margin: 20px 0;
  padding: 0;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
}