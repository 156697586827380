div.pricing-table {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: -10px;

    @media screen and (max-width: 1180px){
        flex-direction: column;
        align-items: center;
    }

    .plan {
        &:not(.skeleton) {
            border: 1px solid #e0e0e0;
        }
        border-radius: 5px;
        width: 320px;
        min-width: 230px;
        margin-right: 10px;
        margin-bottom: 10px;
        text-align: center;
        position: relative;
        .plan-content {
            padding: 20px 20px 60px 20px;
        }
        .plan-name {
            font-size: 18px;
            font-weight: 700;
            color: #0e0e2c;
            margin-bottom: 10px;
        }
        .plan-description {
            min-height: 50px;
        }
        .plan-price {
            font-size: 14px;
            color: #555;
            .plan-price-value {
                font-size: 24px;
                color: #0e0e2c;
                font-weight: 700;
                display: block;
            }
        }
        select {
            display: inline-block;
            width: 60px;
            margin-right: 8px;
            padding: 2px;
            border: 1px solid #e0e0e0;
            background-color: white;
            border-radius: 3px;
            color: #555;
        }
        .plan-credits {
            margin: 10px 0;
        }
        button {
            margin: auto;
            position: absolute;
            bottom: 20px;
            left: 0px;
            right: 0px;
            width: 130px;
        }
    }
    .plan.trial-plan {
        border: 1px solid #f68c3e;
        .plan-content {
            padding-top: 0;
        }
        .trial-badge {
            background-color: #f68c3e;
            color: white;
            font-weight: 700;
        }
    }
}