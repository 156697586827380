#file_upload {
	.profile_wrapper {
		display: flex;
		align-items: center;
		.profile_upload {
			img {
				width: 96px;
				height: 96px;
				border-radius: 50%;
				object-fit: cover;
				object-position: center;
				background: #ECF1F4;
			}
		}
		p {
			font-family: Nunito, sans-serif;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 22px;
			letter-spacing: 0.02em;
			color: #004385;
			margin-left: 20px;
		}
	}
	.file_wrapper {
		width: 480px;
		p {
			font-family: Poppins, sans-serif;
			font-style: normal;
			font-weight: normal;
			line-height: 24px;
		}
		.supported_file {
			font-size: 12px;
			text-align: center;
			letter-spacing: 0.02em;
			color: #8C8CA1;
			margin: 0;
		}
		.file_upload {
			padding: 32px;
			background: #FFFFFF;
			border: 2px dashed #8C8CA1;
			box-sizing: border-box;
			border-radius: 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			p {
				font-size: 14px;
				letter-spacing: 0.15px;
				color: #0E0E2C;
			}
			.file_upload_container {
				text-align: center;
				p {
					margin-left: 0;
				}
			}
		}
	}
}