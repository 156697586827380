@import './src/styles/fonts.styles.scss';
@import './src/styles/scroll.scss';

.update-interview-duration-modal.modal-container {
  max-width: 500px;
  .duration-modal-header {
    padding-bottom: 10px;
    h2 {
      color: $dark;
      font-size: 20px;
    }
    small {
      color: $slate;
    }
  }
  .slider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .slider-container {
      width: 95%;
      margin: 0 auto;
      .MuiSlider-root {
        color: $blue;
        .MuiSlider-rail {
          color: $success;
          height: 4px;
        }
        .MuiSlider-track {
          height: 4px;
        }
        .MuiSlider-mark {
          height: 4px;
          color: white;
        }
      }
    }
    .time-increments {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: -10px;
      p {
        width: 45px;
        white-space: nowrap;
        &.selected {
          font-weight: 800;
        }
        @media only screen and (max-width: 768px) {
          &.intermediary-increment {
            display: none;
          }
          &.forty-five-min {
            padding-right: 25px;
          }
          &.ninety-min {
            padding-left: 5px;
          }
        }
      }
    }
  }
}
