@import '../../../styles/colors.scss';

.participantsKanbanWrapper {
  display: block;
}

.participantsKanban {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.column {
  background-color: white;
  border: 1px solid $line;
  border-radius: 5px;
  min-width: 240px;
  min-height: calc(80vh - 400px);
  .columnTitle {
    padding: 5px;
    color: $dark;
    margin: 10px 0;
    text-align: center;
  }
  &.dragging {
    background-color: $disabled;
  }
  .candidateCards {
    display: flex;
    flex-direction: column;
  }
}

.column:not(:last-child) {
  margin-right: 15px;
}