@import '../../../styles/colors.scss';

.candidateCard {
  display: flex;
  flex-direction: column;
  background-color: $hover;
  padding: 10px;
  border: 1px solid $line;
  border-left: 3px solid $blue;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-left: 10px;
  &.dragging {
    background-color: $hover;
  }
  .piiData {
    text-overflow: ellipsis;
    overflow-x: hidden;
    margin: 5px 0;
  }
  .statusBadge {
    padding: 2px 10px;
    border-radius: 5px;
    background-color: $disabled;
    color: white;
    width: fit-content;
    margin-bottom: 5px;
  }
  .invitedOn {
    display: flex;
    align-items: center;
    color: $grey;
  }
  .icon {
    padding: 0;
    margin-right: 10px;
  }
}
.dragging {
  background-color: $line;
}