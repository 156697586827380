@import '../../../styles/fonts.styles.scss';
.modal-container {
  h2 {
    color: $grey;
  }
  ul {
    list-style: none;
    li::before {
      content: "\2022";
      color: $slate;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

	hr {
		color: $line;
		background-color: $line;
		height: 1px;
		border: none;
		margin: 18px auto;
	}
	.close-button{
		visibility: hidden;
	}
	.delete-modal-content{
		text-align: left;
	}
  &.delete-participant-modal {
    max-width: 420px;
  }
	.actions-buttons {
		margin-top: 0px;
		justify-content: end;
		width: 100%;
		flex-direction: row-reverse;
		.expiry-back-button {
			color: $link;
			border: none;
			background: none;
			min-width: unset;
			margin-right: 10px;
			cursor: pointer;
		}
		.expiry-back-button:hover {
			box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
			border-radius: 5px;
		}
	}
	b, li {
		color: #0E0E2C;
	}
	.retake-checkbox-wrapper {
		margin-top: 8px;
	}
}
.extend-expiry {
	.modal-content {
		width: 100%;
	}
	.row{
		display: flex;
		justify-content: space-between;
		.dropdown_select, .datetime-picker-wrapper {
			width: 48%;
		}
	}
	.extend-expiry-data{
		margin: 20px 0 0 0;
		.data-row {
			display: flex;
			margin: 10px 0;
			overflow-wrap: break-word;
			.title {
				width: 100px;
				font-weight: normal;
				color: $disabled;
				font-size: 16px;
			}
			.value {
				margin-left: 10px;
				width: 80%;
			}
		}
	}
	.main-container{
		border: none;
		box-shadow: none;
		.container{
			padding: 0;
			width: 100%;
		}
	}
}
.extend-switcher {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: 4px;
	p {
		margin-top: 12px;
		font-size: 16px;
	}
}
.execution-modal-buttons {
	display: flex;
	flex-direction: row-reverse;
	margin-top: 20px;
	@media only screen and (max-width: 800px) {
		flex-direction: column-reverse;
		align-items: center;
		* {
			margin-top: 5px;
		}
	}
}

.coding-output-wrapper {
	max-height: 30vh;
	overflow-y: auto;
}