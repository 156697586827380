main:has(> .report-container) {
  flex: 1;
}
.report-download-btn {
  display: none;
}
.report-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  .report {
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    width: 1200px;
    font-family: 'Work Sans', sans-serif;
    .report-header {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
      min-height: 70px;
      padding: 10px 50px;
      margin-bottom: 30px;
      .report-logo {
        flex: 1;
      }
      .report-title {
        flex: 1;
        font-weight: bold;
        text-align: center;
        .report-role-title {
          color: #004385;
        }
      }
      .report-contact-info {
        flex: 1;
        font-size: 14px;
        .report-contact-text {
          width: fit-content;
          float: right;
        }
      }
    }
    .report-body {
      .report-section-title {
        padding: 10px 50px;
      }
      .report-section {
        padding: 10px 50px;
        background-color: white;
        flex-direction: column;
        &.report-introduction {
          background-color: transparent;
          strong {
            color: #004385;
          }
        }
        .report-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-top: 20px;
          .report-graph {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;
            &.right {
              align-items: flex-end;
            }
            .graph-title {
              font-weight: bold;
              font-size: 14px;
              margin-left: 40px;
            }
          }
        }
        &.report-overall-performance {
          display: flex;
          align-items: center;
          justify-content: center;
          .percentile-text {
            color: #004385;
            font-size: 20px;
          }
          .skills-scores {
            align-self: center;
            justify-content: space-around;
            flex: 1;
            width: 100%;
            .subject-score {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
            .active .scored-percent strong {
              font-size: 32px;
            }
          }
        }
      }
    }
  }
}