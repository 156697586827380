@import 'styles/responsiveness.scss';
.funnel_main_hood {
  height: 100%;
  min-height: 250px;
  display: inline-grid;
  width: 100%;
  background: white;
  margin: 0px;

  .funnel-tooltip-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .funnel-tooltip-icon {
    margin-left: 3px;
  }

  .funnel-hood {
    width: 100%;
  }
  .dv_all {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .dv_point {
    font-size: 1em;
    color: #4a4a68;
    font-weight: bold;
  }

  .dv_label {
    font-size: 0.7em;
    line-height: 13px;
    padding-left: 1px;
    width: 90%;
  }
  .dv_labels {
    display: inline-flex;
  }
}
