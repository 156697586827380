@import '../../../styles/colors.scss';

.how-is-this-calculated-container {
    button {
        color: $orange;
        font-size: 14px;
        font-weight: bold;
    }
}

.how-is-this-calculated-modal {
    .close-button {
        visibility: visible;
    }

    .popup-content-box {
        h1 {
            font-size: 32px;
        }
        p {
            margin: 8px 0 12px 0;
        }

    }
}