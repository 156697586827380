body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #fafafa;
  height: 100%;
}

main,
main:before,
main:after {
  box-sizing: border-box;
}

.content {
  box-sizing: inherit;
}

._loading_overlay_wrapper {
  height: 100% !important;
}
.hide {
  visibility: hidden;
}
.cursor-pointer {
  cursor: pointer;
}
hr {
  width: 100%;
  border: 1px solid #dedfe1;
  border-bottom: 0;
  margin: 20px 0;
}
.max-content {
  width: max-content;
}
.center {
  text-align: center;
}
.assessment-description {
  font-size: 15px;
  color: #555;
  font-weight: normal;
}