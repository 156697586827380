@import './src/styles/colors.scss';

.skill-resource-container{
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 20px;
  .skill-resource{
    a {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.02em;
      color: $blue;
      img {
        margin-right: 5px;
      }
    }
  }
}
