@import '../../../styles/colors.scss';

$barHeight: 6px;
.progress-wrapper {
  .progress-upper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .progress-title {
      text-align: left;
    }
    .progress-legend {
      color: $disabled;
    }
  }
  .progress-bar {
    border-radius: calc(#{$barHeight} / 2);
    background-color: $line;
    width: 100%;
    height: $barHeight;
    .progress-fill {
      border-radius: calc(#{$barHeight} / 2);
      background-color: $blue;
      height: $barHeight;
    }
  }
}
