.input-collapse {
  display: none;
}

.icon-collapse {
  position: absolute;
  z-index: 10;
  width: 20px;
  height: 20px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  right: -10px;
  background-color: #fff;
  transition: margin .3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.side-navigation-panel::-webkit-scrollbar {
  width: 6px;
}

.side-navigation-panel::-webkit-scrollbar-thumb {
  background-color: #8C8CA1;
}

.side-navigation-panel::-webkit-scrollbar-track {
  color: red;
  background-color: white;
}

.side-navigation-panel {
  box-sizing: border-box;
  height: calc(100vh - 250px);
  overflow-y: auto;
  border-width: 0;
  width: 240px;
  transition: width .3s;
  border-style: solid;
  border-color: #e2e8f0;
  background-color: #fafafa;

  @media only screen and (max-width: 991px) {
    width: 90%;
    .icon-collapse {
      display: none;
    }
  }

  .menu-show {
    display: block;
    opacity: 1;
    background-color: #ECF1F4;
    height: 100%;
    z-index: 999;
    text-align: center;
  }
}

.active {
  display: block;
}

nav.collapsed {
  width: 58px;
}

.side-navigation-panel-select {
  padding: 0;
  margin:0;
  overflow: hidden;
  color: #004385;
}

.side-navigation-panel-select-wrap {
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 240px;
  transition: width 5s;
  line-height: 22px;
  letter-spacing: 0.02em;
}

.side-navigation-panel-select-option-wrap {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px;
  width: 100%;
}

.side-navigation-panel-select-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-left: 2px solid transparent;
  font-size: 16px;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  svg {
    position: absolute;
    right: 16px;
  }
}

a.side-navigation-panel-select-option {
  &:hover {
    text-decoration: none;
  }
}

.side-navigation-panel-select-option-selected {
  border-left: 2px solid #F68C3E;
}

.side-navigation-panel-select-inner {
  padding: 0;
  margin: 0;
  margin-top: -8px;
}

.side-navigation-panel-select-inner-wrap {
  display: flex;
  padding: 8px 0;
  cursor: pointer;
  border-left: 2px solid transparent;
}

.side-navigation-panel-select-inner-option {
  display: flex;
  align-items: center;
  width: 100%;
  letter-spacing: 0.02em;
  text-decoration: none;
  font-size: 16px;
  &:hover {
    color: #F68C3E;
    text-decoration: none;
  }
}

.side-navigation-panel-select-inner-option-selected {
  border-left: 2px solid #F68C3E;
  color: #F68C3E;
}

.side-navigation-panel-select-inner-option-selected a {
  color: #F68C3E;
}

.side-navigation-panel-select-option-text {
  font-weight: 600;
  margin-left: 16px;
}

.point-container {
  width: 24px;
  height: 24px;
  text-align: center;
  margin: 0 16px;
}

.point {
  display: inline-block;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: #ECF1F4;
}

.blue-point {
  background-color: #004385;
}

aside {
  .icon-collapse {
    visibility: hidden;
  }

  &:hover {
    .icon-collapse {
      visibility: visible;
    }
  }
}
