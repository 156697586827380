@import '../../../styles/responsiveness';
@import '../../../styles/fonts.styles';
@mixin flex-display {
  display: flex;
  align-items: center;
}

@mixin table-font-family {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  letter-spacing: -0.02em;
  /* text-slate */
  color: #4a4a68;
  white-space: nowrap;
  .tooltiptext {
    white-space: normal;
  }
}

@mixin table-search-font-family {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.02em;
  /* light-slate-sub-text */
  white-space: nowrap;
}
.dynamic-table-container {
  width: 100%;
  border-radius: 4px;
  max-width: calc(100vw - 120px);
  &.full {
    max-width: calc(100vw - 330px);
    @media only screen and (max-width: 992px) {
      max-width: calc(100vw - 65px);
    }
  }
  @media screen and (max-width: 992px) {
    max-width: calc(100vw - 30px);
  }
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  .dynamic-paper-container {
    box-shadow: unset;
    position: relative;
    &.container-loading {
      overflow: hidden;
    }
  }
  table {
    .action-menu-cell {
      padding: 0 0 0 16px;
      min-width: 30px;
    }
    td,
    th {
      min-width: max-content;
      max-height: max-content;
      color: $slate;
    }
    .MuiTableSortLabel-root.MuiTableSortLabel-active {
      color: $slate !important;
    }
    .dynamic-table-row {
      background-color: white;
    }
    .dynamic-table-row:nth-child(even) {
      background-color: #fafafa;
    }
    .dynamic-table-row:hover {
      background-color: $hover !important;
    }
    .dynamic-table-row:not(.organization-users-table):hover  a {
      color: $orange;
    }
    .dynamic-table-row:hover .text-muted {
      color: $orange;
    }
    .dynamic-table-row:hover .primary-color {
      color: $orange;
    }
    .dynamic-table-row:hover .success {
      color: $orange;
    }
    .dynamic-table-row:hover .underline-on-hover {
      text-decoration: underline !important;
    }
    .dynamic-table-row:hover .action-menu-cell {
      z-index: 2;
    }
    .dynamic-table-row:hover .tooltiptext {
      z-index: 3 !important;
    }
    .dynamic-table-row.inactive a {
      color: #aaa;
    }
    .dynamic-table-cell {
      @include table-font-family;
      padding: 12px;
      a {
        color: #4a4a68;
      }
      .last-col {
        font-weight: normal;
      }
    }
    @include table-font-family;
  }
  .dynamic-table {
    z-index: 1;
    &.MuiTable-stickyHeader {
      .MuiTableCell-stickyHeader {
        @media only screen and (max-width: 768px) {
          position: unset;
        }
      }
    }
  }
  .table-loading {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 2;
    width: inherit;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
  .dynamic-table-search {
    display: flex;
    align-items: center;
    svg {
      margin-bottom: 10px;
      background: #ffffff;
    }
    input {
      border: none;
      background: #ffffff;
      border-bottom: 1px solid #8c8ca1;
      outline: none;
      @include table-search-font-family;
    }
    input:focus,
    input:active {
      outline: none;
    }
    input::placeholder {
      @include table-search-font-family;
      opacity: 0.7;
    }
    .icon {
      padding: 8px;
      cursor: pointer;
      display: flex;
      align-self: flex-start;
      div {
        svg {
          margin-top: -5px;
          margin-left: -8px;
        }
      }
      svg {
        margin-top: 4px;
      }
    }
  }
  .input-container {
    width: 360px;
    cursor: pointer;
  }

  .dynamic-table-paginator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 65px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.15px;
    /* text-slate */
    color: #4a4a68;
    margin: 16px auto 0;
  }
  .paginator {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .paginator-actions {
      display: flex;
      justify-content: flex-end;
      div.action {
        margin: auto 32px;
        display: inherit;
      }
      span {
        margin: auto 4px;
      }
      .dash {
        margin: 0px;
      }
      .of {
        margin: auto 6px;
      }
      .dropdown_select {
        margin: auto 20px;
        min-width: max-content;
        div {
          border: none;
        }
        .dropdown___value-container {
          padding: 2px 24px;
        }
        * {
          cursor: pointer;
        }
      }
    }
    .paginator-arrows {
      .left {
        margin-right: 24px;
        cursor: pointer;
      }
      .right {
        cursor: pointer;
      }
    }
  }
  .skeleton {
    width: calc(50% - 16px);
    margin-top: 16px;
    margin-right: 16px;
    display: unset;
  }
  .action-menu-wrapper{
    width: fit-content;
  }
  .top-stat {
    .action-menu-wrapper {
      padding-bottom: 16px;
      .action-menu {
        bottom: 30px;
        top: auto;
        right: 10px;
      }
    }
    .tooltiptext {
      top: unset;
      bottom: 150%;
    }
  }
  .single-stat {
    .action-menu-wrapper {
      padding-bottom: 16px;
      .action-menu {
        bottom: 0;
        top: auto;
        right: 10px;
      }
    }
  }
  .down-stat {
    .action-menu-wrapper {
      padding-bottom: 16px;
      .action-menu {
        // top: 30px;
        right: 10px;
      }
    }
  }

  ::-webkit-scrollbar {
    top: -5px;
    height: 6px;
    scrollbar-width: thin;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-track {
    background: $mainBackground;
  }
  ::-webkit-scrollbar-thumb {
    background: $slate;
  }
}
