.custom-text-input{
  .MuiFormLabel-root {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #8C8CA1;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.Mui-focused {
      font-size: 13px;
      line-height: 17px;
      color: #4A4A68;
      transform: scale(1) !important;
    }
    &.MuiFormLabel-filled {
      font-size: 13px;
      line-height: 17px;
      color: #4A4A68;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.MuiInputLabel-shrink{
      transform: scale(1) !important;
    }
  }
  &.main-text-field{
    input {
      width: 270px;
    }
  }
  &.more-text-field{
    input {
      width: 200px;
    }
  }
  .MuiInput-underline{
    height: 40px;
  }
  .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #8C8CA1;
  }
  .MuiInput-underline:after {
    border-bottom: none;
  }
}