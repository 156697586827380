@import '../../styles/colors.scss';
@import '../../styles/responsiveness.scss';

.organization-settings {
  .row {
    display: flex;
    justify-content: space-between;
    
    .alooba-input {
      width: calc(50% - 12px);
      background: red;
    }

    .name-and-logo {
      display: flex;
      background: red;
    }
  }
  .recruitment-company {
    .radio-buttons {
      margin-top: 24px;
      .radio-button {
        margin-right: 32px;
      }
    }
  }
}
.organization-details {

  .row {
    .name-and-logo {
      background-color: rgba($line, 0.4);
      padding: 20px;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      border-radius: 5px;

      & > * {
        flex-grow: 1;
      }
      h3 {
        width: 100%;
      }

      .color-selection {
        margin-top: 20px;
        display: flex;
        grid-gap: 10px;
        align-items: center;
        .color-picker-label {
          font-size: 12px;
          line-height: 16px;
          color: #4a4a68;
        }
      }
      
      .org-logo-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          object-fit: contain;
          max-height: 100px;
        }
        .file_wrapper {
          margin: 0 auto;
          cursor: pointer;
        }
      }

      @include respond(laptop) {
        #file_upload .file_wrapper {
          max-width: calc(100vh / 2);
        }
      }
      .alooba-input {
        margin-top: 0px;
      }

      @include respond(phone) {
        flex-direction: column;
        &> * {
          width: 100%;
        }
        .org-logo-container {
          img {
            max-width: 80%;
          }
        }
        #file_upload .file_wrapper {
          margin-top: 15px;
          width: initial;
          .supported_file {
            padding-top: 5px;
            line-height: 1em;
            margin-bottom: 5px;
          }
        }
      }

      @include respond(tablet) {
        &> * {
          width: 100%;
        }
      }

    }
  }
}
