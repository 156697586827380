.sso-title {
  margin-top: 32px;
  margin-bottom: 16px;
}
.sso-identity-provider{
  display: flex;
  align-items: center;
  margin: 15px 0;
  .sso-identity-provider-name {
    display: flex;
    align-items: center;
    img {
      height: 32px;
      object-position: left;
      object-fit: scale-down;
      margin-top: 2px;
      margin-right: 8px;
    }
  }

  button {
    position: absolute;
    margin-left: 250px;
    min-width: 150px;
  }
}