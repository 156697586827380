div.billing-details {
    margin-bottom: 28px;
    margin-top: 24px;
    .form-row {
        display: flex;
        justify-content: space-between;
        margin-left: - 16px;
        .alooba-input {
            width: 48%;
            margin-left: 16px;
            margin-top: 10px;
            label {
                line-height: 22px;
            }
        }
    }
}