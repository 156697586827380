@import '../../../styles/colors.scss';




$gridColor: rgba($slate, 0.3);
$gridLabelColor: $slate;
$tooltipBackgroundColor: $mainBackground;


.self-awareness-chart-wrap {
  width: 100%;
  svg {
    min-width: 316px;
  }
}
.chart-svg {
  font-size: 0.2rem;
  .plot {
    .tooltip {
      transform-origin: 50% 50%;
      transition:.3s;
      transform-box: fill-box;

      fill: $tooltipBackgroundColor;
      filter: drop-shadow(1px 1px 1px rgba(black, 0.3));
      text.skill-name {
        dominant-baseline: middle;
        fill: $dark;
        font-weight: bold;;
      }
      foreignObject.skill-name {
        div {
          font-weight: bold;
          font-size: 3px;
          color: $dark;
          font-family: 'Nunito Sans', sans-serif;
        }
      }
      text.accuracy {
        fill: $blue;
      }
      text.rating-label {
        fill: $dark;
      }
      text.score {
        fill: $dark;
      }
      .balloon {
        fill: $tooltipBackgroundColor;
        filter: drop-shadow(1px 1px 30px rgba(black, 0.1));
      }
    }
    .grid-line {
      stroke: $gridColor;
      stroke-width: 0.2;
    }
    .overestimate {
      fill: $error;
    }
    .underestimate {
      fill: $blue;
    }
    .accurate {
      fill: $success;
    }

    .grid-label {
      font-size: 0.2rem;
      fill: $gridLabelColor;
      &.legend {
        text-anchor: middle;
      }
      &.vertical{
        text-align: center;
        text-anchor: middle;
      }

      &.horizontal {
        text-anchor: end;
        dominant-baseline: middle;
      }
    }

    .accuracy-baseline {
      stroke: $success;
      stroke-width: 0.3;
      stroke-dasharray: 2;
    }

    .skill-level-estimation-labels {
      font-size: 0.2rem;
      transform-box: fill-box;
      transform-origin: center;
      transform: rotate(-45deg);
      text-anchor: middle;
    }

    .datapoint {
      text {
        font-size: 0.2rem;
        text-anchor: middle;
        dominant-baseline: middle;
        fill: white;
        font-weight: bold;

        cursor: default;
      }
      circle {
        filter: drop-shadow(1px 1px 1px rgba(black, 0.3));
      }
    }

  }
}