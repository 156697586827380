.free-trial-modal {
    .trial-modal-header {
        margin-bottom: 24px;
    }
    .modal-footer {
        border-top: 1px solid #e0e0e0;
        padding-top: 28px;
        .modal-footer-actions {
            display: flex;
            justify-content: center;
            margin-top: 28px;
            .primary {
                margin-left: 14px;
            }
        }
    }
}