@import "../../styles/colors.scss";

.suggested-candidates {
  .top-caption {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .name {
    color: $blue;
  }
}