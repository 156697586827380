@import './src/styles/colors.scss';

.subject-details {
  text-align: justify;
  text-justify: inter-word;

  .subject-details-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    img {
      width: 20px;
      margin: 0 0 20px 10px;
    }
  }

  h3 {
    color: $dark;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: $dark;
    margin-top: 20px;
    white-space: pre-line;
  }

  .ai-text-disclaimer {
    color: $grey;
    font-size: 12px;
    background-color: $light-border;
    padding: 10px;
  }

  .alert {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;
    background: $mainBackground;
    border-radius: 5px;
  }

  .topics-container {
    margin-top: 20px;

    .topic-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 500px;
      color: $grey;
      font-weight: 600;
      margin-top: 5px;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.02em;

      .line {
        flex-grow: 1;
        border-bottom: 1px solid #ecf1f4;
        margin: 0 13px;
      }

      .scored-percent {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.02em;
      }
    }
  }

  .spinner-container {
    padding: 20px 0 20px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}