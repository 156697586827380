@import '../../styles/colors.scss';


.score-input-field {
    text-align: center;
    border: solid 1px $line;
    color: $line;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 5px;
    border-radius: 5px;
    width: 100px;

    .max-score {
        margin-top: 1px;
        color: $dark;
        font-size: 15px;
    }

    input[type=number] {
        border: none;
        height: 100%;
        padding: 0;
        padding-left: 15px;
        width: 100%;

        font-weight: bold;
        font-size: 15px;
        text-align: center;
    
        &:focus {
            outline: none;
            border: none;
        }
    }
    ::-webkit-input-placeholder {
        text-align: center;
    }

    :-moz-placeholder { /* Firefox 18- */
        text-align: center;  
    }

    ::-moz-placeholder {  /* Firefox 19+ */
        text-align: center;  
    }

    :-ms-input-placeholder {  
        text-align: center; 
    }
}