div.restricted-access {
    background-color: #f9fafb;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    .card {
        background: white;
        max-width: 576px;
        width: 100%;
        padding: 32px;
        background-color: #fff;
        border: 1px solid #DEDFE1;
        box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.0509803922);
        border-radius: 5px;
    }
    .head {
        text-align: center;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        font-family: 'Nunito Sans', sans-serif;
        .icon {
            font-size: 24px;
            color: #f59e0b;
        }
        .title {
            font-size: 24px;
            font-weight: 600;
            color: #111827;
            margin: 0;
            line-height: 1.5;
        }
    }

    .alert-box {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .alert {
            background-color: #fffbeb;
            border: 1px solid #fcd34d;
            color: #92400e;
            border-radius: 5px;
            padding: 16px;
            font-size: 16px;
            text-align: center;
        }

        .message {
            text-align: center;
            color: #4b5563;
            font-size: 16px;
            line-height: 1.5;
        }

        .support {
            font-size: 14px;
            color: #6b7280;
            margin-top: 24px;
            a {
                color: #2563eb;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                    color: #1d4ed8;
                }
            }
        }
    }
}

@media (max-width: 640px) {
    div.restricted-access {
        .card {
            padding: 24px;
        }
        .head {
            .title {
                font-size: 20px;
            }
            .icon {
                font-size: 20px;
            }
        }

        .alert-box {
            .alert,
            .message {
                font-size: 14px;
                text-align: center;
            }
        }
    }
}