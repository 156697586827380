
  .no-group-content {
    text-align: center;
    max-width: 500px;
    margin: auto;
    padding: 100px 32px;
    button {
      display: inline-block;
    }
    p {
      white-space: pre-line;
    }
  }