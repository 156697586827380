.btn-no-background {
  background: transparent;
  padding: 0;
  box-shadow: none;
  border: none;
  min-width: auto;
  &:hover:not([disabled]) {
    transform: none;
  }
}
.btn-40-height {
  height: 40px;
}
.btn-filter {
  background: transparent;
  border: 1px solid #ECF1F4;
  box-sizing: border-box;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  outline: none;
  min-width: 90px;
  text-align: left;
  justify-content: space-between;
  font-family: Nunito Sans, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #0e0e2c;
  &:hover:not([disabled]) {
    transform: none;
  }
  .icon {
    color: #004385;
  }
  img {
    margin-top: -3px;
    margin-left: 2px;
  }
}
.score-btn-select {
  .MuiSelect-select.MuiSelect-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-right: 12px;
  }
  .MuiSelect-icon {
    display: none;
  }
  .icon {
    color: #004385;
    margin: 0 8px 0 5px;
  }
}
.score-btn-select-menu{
  .MuiPaper-root {
    margin-top: unset;
    min-width: 120px;
    width: auto;
    max-width: 200px;
  }
}
.filter-container-grid {
  position: relative;
}
.download-results-icon-container {
  align-self: flex-start;
  flex: auto;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: -5px;
}