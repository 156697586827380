@import '../../styles/colors';
@import '../../styles/fonts.styles.scss';
@import '../../styles/responsiveness.scss';

@mixin pill {
  white-space: nowrap;
  display: inline;
  padding: 2px 12px;
  margin: 1px 4px 1px 0;
  background: $medium-grey;
  color: $grey;
  font-size: 0.8em;
  border-radius: 20px;
}

.generating-assessment-modal {
  width: 450px;
  .generating-assessment-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 10px;
    svg {
      font-size: 30px;
      color: #4a4a68;
    }
  }
}
.main-container.template-intro {
  padding: 40px;
  color: $dark;
  display: flex;
  align-items: center;
  position: relative;
  @media screen and (max-width: 480px) {
    padding: 40px 30px;
  }

  .template-intro-text {
    min-width: 50%;
    max-width: calc(100% - 320px);
    @media screen and (max-width: 768px) {
      max-width: 100%;
      margin-bottom: 60px;
    }
  }
  .template-hero-image {
    width: 320px;
    margin-right: 20px;
    img {
      width: 100%;
      min-width: 230px;
      min-height: 180px;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  button {
    position: absolute;
    bottom: 40px;
    right: 40px;

    &#generate-assessment {
      right: 290px;
    }
  }
}

.template-header, .details-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .action-menu-wrapper {
    margin-top: -16px;
    margin-left: 10px;
  }
  h3 {
    margin: auto;
    margin-top: 18px;
    font-weight: 700;
    font-size: 24px;
    color: $dark;
  }
}

.template-sub-header {
  color: $grey;
  margin-top: 4px;
  margin-bottom: 15px;
  font-size: 0.9rem;
  font-weight: 100;
}

.template-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: fit-content;
  margin: 8px auto;
  & > div {
    padding: 8px;
    min-width: 240px;
  }
}
.empty-template-filter-results {
  text-align: center;
  margin-top: 40px;
}
.main-input {
  width: 50%;
  min-width: 400px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  h2 {
    text-align: center;
    margin-bottom: 24px;
  }
  .break {
    margin-bottom: 80px;
  }
}
.no-role-found {
  text-align: center;
  margin: auto;
  margin-top: 24px;
  min-width: 400px;
  width: 50%;
  p {
    margin-top: 20px;
  }
  .create-custom-assessment {
    margin-top: 20px;
    button {
      margin: auto;
    }
  }
}

.template-summary {
  text-align: center;
  h2 {
    white-space: normal;
    word-wrap: break-word;
    display: inline-block;
    padding: 10px 20px;
    cursor: text;
    font-weight: 700;
    font-size: 32px;
  }
  h2:hover {
    background-color: $mainBackground;
  }
  .editable-input {
    margin-top: 4px;
    margin-bottom: 26px;
    .alooba-input {
      max-width: 500px;
      margin: auto;
    }
  }
  .template-options {
    display: flex;
    justify-content: center;
    gap: 10px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    }
    .head {
      font-weight: 400;
      font-size: 13px;
      color: #4A4A68;
    }
    .value-wrapper {
      width: 220px;
      max-width: 220px;
      .value {
        color: $blue;
        margin-top: 4px;
        font-size: 16px;
        cursor: text;
        padding: 10px 32px;
        &:hover {
          background-color: $mainBackground;
        }
      }
      .select-dropdown {
        text-align: start;
        margin-top: 4px;
      }
    }
  }
}

.generating-assessment {
  margin-top: 24px;
  .generating-assessment-text {
    margin-top: 20px;
    text-align: center;
  }
  .fa-spin {
    margin-top: 40px;
  }
  text-align: center;
}

.action-buttons {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}

.create-template {
  margin-top: 30px;
  text-align: center;
  button {
    margin: 0 auto;
  }
}

.template-details {
  margin-top: 30px;
  h2 {
    margin: auto;
    margin-top: 14px;
  }
  h5 {
    font-weight: 700;
    font-size: 16px;
    color: #4A4A68;
    margin-top: 20px;
  }
  p {
    font-weight: 400;
    color: black;
    font-size: 16px;
    line-height: 1.7;
  }
  .job-titles {
    .job-title {
      @include pill;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .skills {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    .skill {
      background-color: #D9D9D9;
      color: #4A4A68;
      padding: 4px 10px;
      margin-right: 12px;
      font-size: 14px;
      font-weight: 700;
      border-radius: 20px;
      white-space: nowrap;
      margin-top: 4px;
    }
  }
  .parts {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 16px;
    .part-container {
      display: flex;
      align-items: center;
      min-width: 350px;
      width: 60%;
      margin: auto;
      margin-top: 26px;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
      @media screen and (max-width: 1200px) {
        width: 80%;
      }
      .part-index {
        position: absolute;
        left: -60px;
        top: 50%;
        @media screen and (max-width: 900px) {
          top: -40px;
          left: 50%;
        }
        transform: translateY(-50%);
        display: inline-block;
        width: 37px;
        height: 37px;
        border-radius: 18.5px;
        margin-right: 20px;
        background: $blue;
        color: $white;
        font-weight: bold;
        line-height: 37px;
        text-align: center;
        font-size: 20px;
      }
      .part {
        position: relative;
        background: #ECF1F4;
        text-align: left;
        flex: 1;
        padding: 24px;
        box-shadow: 0px 2px 1px 0px #0000001A;
        border-radius: 16px;
        @media screen and (max-width: 900px) {
          margin-top: 50px;
        }
        .type {
          margin-bottom: 16px;
          text-align: center;
          margin: auto;
          font-weight: 700;
          font-size: 20px;
          color: #0E0E2C;
        }
        .description {
          margin-top: 14px;
          font-size: 13px;
          font-weight: 400;
          line-height: 18;
        }
        .stats {
          display: flex;
          justify-content: space-between;
          margin-top: 12px;
          gap: 10px;
          @media screen and (max-width: 900px) {
            flex-direction: column;
          }
          .stat {
            background-color: white;
            border-radius: 5px;
            padding: 12px;
            display: flex;
            align-items: center;
            white-space: nowrap;
            flex: 1;
            .icon {
              height: 16px;
              margin-right: 10px;
            }
            .value {
              font-weight: bold;
            }
            .value, .label {
              color: $grey;
            }
          }
        }
      }
    }
  }
}