.overview-carousel-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
}

.overview-carousel-placeholder-container {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overview-carousel-subject {
  font-size: 14px;
  font-family: 'Nunito', sans-serif;
}
