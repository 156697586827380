.headerWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  a {
    font-family: 'Work Sans', sans-serif;
    font-size: 24px;
    letter-spacing: -0.02em;
    font-weight: bold;
  }
  .breadcrumb {
    font-size: 22px;
    margin: 0 8px;
    font-weight: normal;
  }
  .pageName {
    font-size: 22px;
    margin-top: -2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: normal;
  }
}
.insightsContainer {
  padding: 100px 32px;
  max-width: 500px;
  margin: auto;
  text-align: center;
  font-size: 16px;
  .description {
    margin-top: 16px;
  }
  img {
    max-width: 120px;
    margin-top: 50px;
  }
}