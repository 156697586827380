@import './src/styles/colors.scss';

.full-width-container {
  margin: 30px 50px;
  @media screen and (max-width: 480px) {
    margin: 10px 10px;
  }

  h3 {
    font-size: 22px;
  }
}

.sub-header + .full-width-container {
  margin-top: 100px;
}

.main-container.test-intro {
  padding: 40px 80px;
  color: $dark;
  display: flex;
  align-items: center;
  @media screen and (max-width: 480px) {
    padding: 40px 30px;
  }

  .test-description {
    margin-right: 60px;
    min-width: 50%;
    max-width: calc(100% - 530px);
    @media screen and (max-width: 768px) {
      max-width: 100%;
      margin-right: 0px;
    }
  }
  .test-hero-image {
    width: 530px;
    img {
      max-width: 100%;
      min-width: 230px;
      min-height: 180px;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

.button-bar {
  background-color: $white;
  border: 1px solid $line;
  box-shadow: 0px 2px 1px 0px #0000000d;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
}

.clear {
  clear: both;
}