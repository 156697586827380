@import "../../styles/colors.scss";
.search-candidates {
    .filters-container {
        float: left;
        margin-top: 20px;
        display: flex;
        margin-bottom: 14px;

        .subject-filter {
            margin-right: 12px;
        }
        .react-datepicker__input-container {
            background: white;
        }
    }
    .name {
        color: $blue;
    }
    &.clickable {
      table.dynamic-table {
        tbody {
          tr.dynamic-table-row:hover {
            .dynamic-table-cell a, .dynamic-table-cell .reference, .dynamic-table-cell .name {
              color: $orange !important;
              text-decoration: underline;
            }
          }
        }
      }
    }
}