.dynamic-tab-container {
  hr {
    height: 2px;
    width: 110%;
    background-color: #dedfe1;
    border: none;
    position: relative;
    top: -10px;
    z-index: 1;
    opacity: 0.2;
  }
}

.assessment-container {
  .tab-container {
    position: fixed;
    top: 145px;
    z-index: 19;
    button {
      box-shadow: none;
      min-height: 48px !important;
    }
  }
}

.MuiTab-wrapper {
  img {
    margin-bottom: 16px !important;
    margin-left: 5px;
    width: 15px;
  }
}