@import "styles/colors.scss";

.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -49%);
  z-index: 700;
  width: inherit;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 500;
}

.modal-container {
  z-index: 100;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  position: relative;
  margin: auto;
  margin-bottom: 16px;
  border-radius: 8px;
  max-height: calc(100vh - 60px);
  .actions-buttons {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    .primary {
      margin-right: 8px;
      margin-left: 8px;
    }
    .secondary {
      margin-right: 8px;
      margin-left: 8px;
    }
    .modal-info {
      display: flex;
      align-items: center;
      margin-right: auto;
      padding: 10px 20px;
      background: $mainBackground;
      border-radius: 5px;
      img {
        margin-right: 10px;
      }
    }
  }
  .close-button {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    background: #004385;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
}
.alerting {
  background: #fff;
  position: relative;
  margin-bottom: 16px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #ECF1F4;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  min-width: 300px;
  max-width: 350px;
  justify-content: space-between;

  .alert-content {
    display: flex;
    .success, .error, .warning {
      display: flex;
      align-items: flex-start;
      padding: 20px 8px;
    }
    .success {
      background: #bae7d4;
    }
    .error {
      background: #e9afa1;
    }
    .warning {
      background: #e9d3ba;
    }
    p {
      margin: 0;
      padding: 16px 32px 16px 8px;
      padding-left: 8px;
      font-family: Work Sans, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.02em;
      color: #4A4A68;
    }
  }
  .close-button {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}

.modal-header {
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 0.3rem;
}
  
.modal-content {
  width: 100%;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-wrapper {
  @media only screen and (max-width: 1440px){
    width: 60%;
  }
  @media only screen and (min-width: 1440px){
    width: 60%;
  }
  @media only screen and (max-width: 1024px){
      width: 70%;
  }
}
@media only screen and (max-width: 992px) {
  .modal-wrapper {
    width: 85%;
  }
  .menu {
    position: fixed;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    background: #fafafa;
    .menu-list {
      margin-top: 15%;
    }
    .close {
      background: #b2b2b4;
      z-index: 101;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: bold;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}
