.with-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  min-height: 18px;
  .actions & a::after,
  #delete &::after,
  &.copy-field::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 220%;
    height: 200%;
  }
  .tooltiptext {
    visibility: hidden;
    background: #ecf1f4;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
    padding: 10px 16px;
    position: absolute;
    z-index: 21;
    bottom: 150%;
    left: 50%;
  }
  h5 {
    font-family: Nunito, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.02em;
  }
  .copied-text {
    visibility: visible;
  }
  .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #ecf1f4 transparent transparent transparent;
  }
  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
}
.highlighted {
  filter: hue-rotate(170deg) brightness(215%) !important;
}
.with-tooltip.no-highlight {
  &:hover {
    filter: hue-rotate(0) brightness(100%) !important;
  }
}
