
.alooba-input {
  margin-top: 20px;
  .text, .password, .textarea, .email, .number, .date {
    background: #FFFFFF;
    border: 1px solid #ECF1F4;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
  }
  input:disabled {
    background: #fafafa;
    box-shadow: unset;
    cursor: not-allowed;
  }
  .textarea {
    min-height: 115px;
  }
  .errorInput {
    border: 1px solid #d62828;
  }
  .errorMsg {
    color: #d62828;
    font-size: 12px;
    margin-top: 0px;
  }
}

.radio-button {
  position: relative;
  margin: 20px 0;
  input[type="radio"]:checked + span .fake-radiobutton:after { display: block; }
  &:not(:first-child) {
    margin-left: 20px;
  }
  input {
    position: absolute;
    margin: 5px;
    padding: 0;
    visibility: hidden;
  }
  .label-visible {
    margin-left: 42px;
    margin-bottom: -16px;
    font-size: 16px;
    font-weight: 600;
    .fake-radiobutton, .radio-placeholder {
      position: absolute;
      display: block;
      top: -5px;
      left: 3px;
    }
    .fake-radiobutton {
      width: 20px;
      height: 20px;
      border: 2px solid #4A4A68;
      background-color: white;
      cursor: pointer;
    }
    .disabled {
      cursor: not-allowed;
      border: 2px solid #8C8CA1;
    }
    .fake-radiobutton:after {
      content: "";
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      background: #004385;
      transform: translateX(-50%) translateY(-50%);
    }
    .fake-radiobutton, .fake-radiobutton:after {
      border-radius: 50%;
    }
  }
}

#checkbox_component {
  label {
    display: block;
    position: relative;
    padding-left: 30px;
    font-family: Nunito, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #4A4A68;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  label input {
    display: none;
  }
  
  /* Create a custom checkbox */
  span {
    position: absolute;
    top: 0px;
    left: 0;
    height: 15px;
    width: 15px;
    background: #FFFFFF;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  .checkmark {
    border: 3px solid #4A4A68;
  }
  .disabled {
    border: 3px solid #8C8CA1;
    cursor: not-allowed;
  }
  
  /* On mouse-over, add a grey bother */
  label:hover input ~ .checkmark {
    background: #FFFFFF;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 3px solid #8C8CA1;
  }
  
  /* When the checkbox is checked, add a blue background */
  label input:checked ~ .checkmark {
    background-color: #004385;
    border: 3px solid #004385;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  label input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  label .checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  } 
}
#alooba-search {
  p {
    margin-top: 8px;
    margin-bottom: 0;
  }
  #search_box {
    position: relative;
    box-sizing: border-box;
    .search_input {
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #ECF1F4;
      box-sizing: border-box;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 10px 16px;
      font-size: 16px;
      outline: none;
    }
    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
    }
  }
}