@import '../../../styles/fonts.styles.scss';

.erased-candidate-info {
    display: inline-flex;
    align-items: center;
    color: $disabled;

    .icon {
        margin-right: 5px;
        margin-bottom: 2px;
    }
}