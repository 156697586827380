.custom-search-input {
  position: relative;
  .custom-text-input{
    &.main-text-field{
      input {
        width: 250px;
      }
    }
    &.more-text-field{
      input {
        width: 180px;
      }
    }
    input {
      padding-right: 13px;
    }
  }
  .custom-text-input-icon {
    position: absolute;
    right: 0;
    top: 25px;
    cursor: pointer;
    color: #8C8CA1;
  }
}