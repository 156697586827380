.markdown code * {
  font-family: monospace;
  font-size: 14px;
}

.markdown pre div {
  background-color: transparent !important;
  border: none !important;
  opacity: 1 !important;
  margin: 0.1 !important;
  padding: 0 !important;
}
