@import '../../../../styles/colors.scss';
.question-answer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-radius: 5px;
  color: $dark;
  margin-top: 20px;

  .left-info {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    &.correct {
      background-color: $success;
      p {
        color: $dark;
      }
    }
    &.wrong {
      p {
        color: $dark;
      }
      background-color: $error;
    }
    p {
      display: flex;
      height: 100%;
      flex-direction: row;
      align-items: center;
    }
  }
  .question-answer-text {
    flex-grow: 1;
    margin: 5px 13px;
  }
  .right-icon {
    margin-right: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &.correct {
    border: 2px solid $success;
  }
  &.wrong {
    border: 2px solid $error;
  }
}
.mcq-answer {
  h4 {
    margin: 10px 0;
  }
}