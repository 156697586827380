@import './src/styles/colors.scss';

.scheduled-duration {
  display: flex;
  cursor: pointer;
  align-items: center;
  img.icon {
    height: 1em;
  }
  .label {
    margin: 0px 8px;
    color: $disabled;
  }
  .value {
    font-weight: bold;
    color: $dark;
  }
  .edit-duration-icon {
    margin: 3px 0 0 10px;
  }
}
