.main-container {
  background-color: #fff;
  border: 1px solid #DEDFE1;
  box-shadow: 0px 2px 1px 0px #0000000D;
  border-radius: 5px;
}
form.main-container > .container {
  padding: 30px;
}
div.main-container {
  padding: 20px;
}
