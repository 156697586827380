@mixin respond($breakpoint){
    @if $breakpoint == phone {
        @media only screen and (max-width: 480px){@content};
    }
    @if $breakpoint == tablet {
        @media only screen and (max-width: 768px){@content};
    }
    @if $breakpoint == laptop {
        @media only screen and (max-width: 1400px){@content};
    }
    @if $breakpoint == laptop-strict {
        @media only screen and (max-width: 1400px) and (min-width: 768px){@content};
    }
    @if $breakpoint == desktop {
        @media only screen and (min-width: 1400px){@content};
    }
}