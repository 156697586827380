.StatIcon {
  * {
    margin: 0;
    padding: 0;

    img + p {
      margin-left: 8px;
    }
  }
}
