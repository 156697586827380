@import '../../styles/responsiveness.scss';

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  position: relative;
  bottom: 0px;
  background: #fafafa;

  div {
    margin: 0 36px;
  }

  a {
    color: #004385;
    text-decoration: none;
    margin: 0 8px;
  }
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    .footer-menu-box {
      a {
        margin: 0 8px;
      }
    }
    @include respond(phone) {
      .footer-menu-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        visibility: hidden;
        a {
          visibility: visible;
        }
      }
    }

    @include respond(laptop-strict) {
      margin: 0;
      .footer-menu-box {
        margin:0;
      }
    }
  }
  .copyright {
    color: #888888;
  }
  @media only screen and (max-width: 992px) {
    height: 90px;
    .footer-content {
      flex-direction: column;
      justify-content: center;
      padding: 8px 0px;
    }
    div {
      margin: 0;
    }
  }
}