.edit-score-row {
    display: flex;
    align-items: center;
    margin-left: -10px;
    flex-wrap: wrap;

    *:not(div) {
        margin-right: 10px;
        margin-left: 10px;
    }

    button {
        all: initial;
        cursor: pointer;
    }

    p {
        font-size: 16px;
    }    
}

.edit-score-container {
    .select__control:hover, .select__control-menu-is-open:hover,
    .select__control, .select__control-menu-is-open {
        background: #FFFFFF;
        border: 1px solid #ECF1F4;
        box-sizing: border-box;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        outline: none;
    }

    .select__indicator-separator {
        display: none;
    }

    .select__menu {
        .select__option--is-selected {
            color: #03CEA4;
            background: inherit;
        }
    }
    .select-container {
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;

        button {
            height: 38px;
        }

        .react-select {
            flex-grow: 1;
            flex-basis: 100px;
            margin-right: 10px;
            margin-top: 0;
        }
    }
}


