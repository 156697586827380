@import './src/styles/colors.scss';

button.subject-score{
  color: $blue;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  padding: 5px;
  &:hover {
    transform: none;
    background: $hover;
    border-radius: 5px;
  }
  &.active {
    background: $hover;
    border-radius: 5px;
    color: $dark;
  }
  &.category-section .scored-percent{
    font-size: 12px !important;
  }
}