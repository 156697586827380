@import "../../styles/colors";
@import "../../styles/responsiveness";

.sso-configuration {
  .container {
    .alooba-input {
      input {
        width: 100%;
      }
    }
  }
  .sso-alooba-settings {
    display: flex;
    grid-gap: 15px;

    @include respond(desktop) {
      #sso-service-provider-container {
        flex: 60%;
      }
      #sso-domain-matching {
        flex: 40%;
      }
    }
    @include respond(laptop) {
      flex-wrap: wrap;
      #sso-service-provider-container {
        flex: 100%;
      }
      #sso-domain-matching {
        flex: 100%;
      }
    }
  }
}

#sso-identity-provider-container {
  margin-top: 32px;
}
.sso-cert-container {
  display: flex;
  flex-direction: column;
  .cert-file-select-container {
    display: flex;
    justify-content: right;
    input[type=file] {
      display: none;
    }
  }
}
#sso-domain-matching {
  #domain-matching-input {
    display: flex;
    grid-gap: 5px;
    align-items: flex-start;
    div:first-of-type {
      flex: 100%;
    }

    & > div:nth-child(2) {
      margin-top: 44px;
    }
    margin-bottom: 20px;
  }
  .domain-list {
    margin-bottom: 10px;
  }
  .domain-list-item {
    margin-top: -1px;
    border: $light-border 1px solid;
    display: flex;
    align-items: center;
    .domain-list-name {
      padding-left: 16px;
      line-height: 20px;
      color: $dark;
      flex: 100%;
    }
    .list-item-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 180px;
      .domain-delete-button {
        color: $slate;
      }
    }
  }
}