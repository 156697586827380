@import 'styles/fonts.styles';
.participants-modal {
	hr {
		color: $line;
		background-color: $line;
		height: 1px;
		border: none;
		margin: 18px auto;
	}
	.close-button{
		visibility: hidden;
	}
	.delete-modal-content{
		text-align: left;
	}
	.actions-buttons {
		margin-top: 0px;
		justify-content: end;
		width: 100%;
		flex-direction: row-reverse;
		.expiry-back-button {
			color: $link;
			border: none;
			background: none;
			min-width: unset;
			margin-right: 10px;
			cursor: pointer;
		}
		.expiry-back-button:hover {
			box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
			border-radius: 5px;
		}
	}
}

.confirm-purchase-modal {
	p {
		margin-bottom: 20px;
	}
}

.modal-container {
  h2 {
    color: $grey;
  }
  ul {
    list-style: none;
    li::before {
      content: "\2022";
      color: $slate;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

	hr {
		color: $line;
		background-color: $line;
		height: 1px;
		border: none;
		margin: 18px auto;
	}
	.close-button{
		visibility: hidden;
	}
	.delete-modal-content{
		text-align: left;
	}
  &.delete-participant-modal, &.small-modal {
    max-width: 420px;
  }
	b, li {
		color: $dark;
	}
}