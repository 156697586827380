@import '../../../../styles/colors.scss';

.score-input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .score-input-field {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px $line;
    color: $line;
    padding: 5px;
    border-radius: 4px;
    height: 28px;
    background-color: white;

    &.disabled {
      background-color: $mainBackground;
    }

    &.requires-evaluation {
      border: 2px solid $blue;
    }
    
    .max-score {
      min-width: 30px;
      padding: 0 5px 0 0;
      color: $dark;
    }
    
    input[type=number] {
      min-width: 30px;
      border: none;
      height: 38px;
      padding: 0 0 0 8px;
      margin: 0;
      font-size: 16px;
      
      &:focus {
        outline: none;
        border: none;
      }

      &:disabled {
        background-color: $mainBackground;
      }
    }
    
    .middle-bar {
      padding: 0 5px;
    }
  }

  .hint {
    margin-left: 10px;
    font-size: 14px;
    &.evaluate {
      color: $blue;
      margin-left: 10px;
    }
    &.wrong, &.unanswered, &.bad {
      color: $error;
    }
    &.ok {
      color: $orange;
    }
    &.good, &.correct {
      color: $success;
    }
  }
}