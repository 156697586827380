@import '../../styles/colors';
@import '../../styles/fonts.styles.scss';
@import '../../styles/responsiveness.scss';

.interviews {
  clear: both;
  display: grid;
  column-gap: 25px;
  row-gap: 21px;
  @include respond(desktop) {
    grid-template-columns: repeat(4, 1fr);
  }
  @include respond(laptop) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include respond(tablet) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond(phone) {
      grid-template-columns: repeat(1, 1fr);
      .main-card-content {
          min-height: 0px;
      }
  }

  .main-container {
    padding: 0;
  }
  .main-card-content {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    .card-title {
      @include title-font;
    }
  }

  .card-footer {
    background-color: $mainBackground;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    > div {
      display: flex;
      align-items: center;
    }
    .icon {
      height: 1em;
      margin-right: 8px;
    }
    .value {
      color: $dark;
      font-weight: bold;
    }

    .completed-candidates-stat {
      display: none;
    }
  }
}
