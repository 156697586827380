@import "../../styles/colors.scss";
.feedback-item {
  display: flex;
  justify-content: space-between;
  padding: 13px 0;
  &.anything_else {
    flex-direction: column;
  }
  .feedback-response {
    &.Agree {
      color: #8AC44C;
    }
    &.Strongly-Agree {
      color: #58A71D;
    }
    &.Neutral {
      color: #C4CFB8;
    }
    &.Disagree {
      color: #F68C3E;
    }
    &.Strongly-Disagree {
      color: #f63e3e;
    }
    &.Empty {
      color: $line;
    }
    &.tools_used_for_da {
      font-weight: bold;
    }
    &.anything_else {
      margin-top: 10px;
    }
  }
}
.feedback-item:not(:last-child) {
  border-bottom: 1px solid lightgrey;
}
.feedback-status-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: $dark;
  img {
    width: 26px;
    margin-bottom: 20px;
  }
}
.tab-header{
  h3 {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #0E0E2C;
    margin-bottom: 5px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #0E0E2C;
  }
  .description {
    margin-bottom: 10px;
  }
}