@import 'colors.scss';

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXBiEJoI3ZKyHaQfhf.woff)
    format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunitosans/v6/pe03MImSLYBIv1o4X1M8cc9yAs5jU1ECVZl_86Y.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/nunito/v16/XRXW3I6Li01BKofA-seUb-vIWzgPDEtj.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiGyp8kv8JHgFVrLPTufntAOvWDSHFF.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@mixin heading-font {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  letter-spacing: -0.02em;
  color: $dark;
}

@mixin normal-font {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.02em;
}

@mixin title-font {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: $blue;
}

.color-blue {
  color: $blue;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  letter-spacing: -0.02em;
  color: $dark;
  margin: 0;
}
h1 {
  font-size: 48px;
  line-height: 56px;
}
h2 {
  font-size: 24px;
  line-height: 28px;
}
h3 {
  color: #4a4a68;
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
}
h4 {
  font-size: 16px;
  line-height: 19px;
}
h5 {
  font-size: 14px;
  line-height: 16px;
}
h6 {
  font-size: 12px;
  line-height: 14px;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
p,
a,
.success-text,
.notofication-text,
.error-text,
input,
textarea,
option,
select,
button,
.medium-text,
.small-text,
label,
.hint,
.help,
.disabled-text,
.disabled-text-medium,
.disabled-text-small {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.02em;
}
p,
a,
.success-text,
.notofication-text,
.error-text,
input,
textarea,
option,
select,
.disabled-text {
  font-size: 14px;
  line-height: 22px;
}
p,
.medium-text,
.small-text,
label,
.hint,
.help {
  color: $grey;
}
a {
  color: $link;
}
.success-text {
  color: $success;
}
.notofication-text {
  color: $orange;
}
.error-text {
  color: $error;
}
.medium-text,
.disabled-text-medium {
  font-size: 14px;
  line-height: 19px;
}
.small-text,
label,
.hint,
.help,
.disabled-text-small {
  font-size: 12px;
  line-height: 16px;
}
input[type='text'],
input[type='email'],
input[type='number'],
input[type='password'],
textarea,
option,
select {
  color: $dark;
  padding: 9px 10px;
  width: 100%;
}
.disabled-text,
.disabled-text-medium,
.disabled-text-small {
  color: $disabled;
}
.title {
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;
}
pre {
  background: #eee;
  border-radius: 3px;
  padding: 8px;
}
