.pill-button {
  display: inline-block;
  button {
    font-weight: bold;
    display: flex;
    align-items: center;
    background: #ecf1f4;
    box-shadow: none;
    color: #004385;
    cursor:unset;
    border-radius: 10px;
    border: none;
    .icon {
      padding: 0;
      padding-left: 12px;
      color: #8c8ca1;
    }
    &:hover {
      transform: unset;
    }
    .spinner {
      margin-left: 12px;
      svg {
        padding:0px;
      }
    }
  }
  .remove {
    cursor: pointer;
  }
}
.flat-pill { 
  border-radius: 5px;
  width: fit-content;
  min-width: 40px;
  text-align: center;
  display: inline-block;
  height: 14px;
  padding: 0 4px;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}
.primary {
  background: #004385;
}
.primary-alt {
  color: #004385;
  border: 1px solid #004385;
  font-size: 14px;
  line-height: 14px;
  padding: 8px;
  &:hover {
    transform: scale(1.02);
  }
}
.orange {
  background: #F68C3E;
}
.disabled {
  background: #999;
}