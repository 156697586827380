@import 'styles/colors';
@import 'styles/responsiveness';
@mixin flex-display {
  display: flex;
  align-items: center;
}
.stat-icon-disabled div {
  cursor: auto;
  img, svg {
    filter: saturate(0%) brightness(300%) !important;
  }
  path {
    fill: $blue !important;
  }
}
.stat-icon {
  a {
    display: flex;
  }
  .fa {
    font-size: 16px;
  }
}
.delete-assessment-modal {
  &.modal-container {
    max-width: 520px;
  }
}
:root {
  --assessment-card-height: auto;
}
#dashboard {
  p {
    color: #4a4a68;
  }
  h5 {
    color: #004385;
  }
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .infinite-scroll-wrapper {
    .loadmore {
      text-align: center;
      border-top: 1px solid #dedfe1;
      padding-top: 16px;
      margin-top: 24px;
      color: #004385;
      font-size: 16px;
    }
  }
  .assessment-card {
    padding: 0;
    margin-top: 20px;
    position: relative;
    align-items: flex-start;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 5px;
    text-decoration: none;
    transition: box-shadow 80ms;
    h5 {
      font-family: 'Nunito Sans', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.02em;
    }
    &:hover:not(.skeleton) {
      cursor: pointer;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      .card-title {
        text-decoration: underline;
      }
    }
    .stat-icon,
    .copy-field {
      display: flex;
      align-items: flex-start;
      &:not(:last-child) {
        margin-right: 20px;
      }
      h5 {
        padding: 0 8px;
      }
      p {
        min-width: fit-content;
      }
    }
    .copy-field {
      min-height: 24px;
    }
    .actions {
      margin-top: 20px;
      @include flex-display;
      justify-content: flex-end;
      .flat-pill {
        margin-right: 12px;
        width: 150px;
        white-space: nowrap;
      }
      .stat-icon {
        .with-tooltip {
          @include flex-display;
        }
      }
      .dropdown {
        position: absolute;
        background: #fff;
        z-index: 9;
        border-radius: 5px;
        border: 1px solid #dedfe1;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        p {
          border-radius: 5px;
          background: #fff;
          padding: 4px 20px;
          color: #103662;
          cursor: pointer;
          .icon {
            padding: 8px 8px 8px 0 !important;
          }
          span {
            color: #103662;
          }
          @include flex-display;
          justify-content: flex-start;
          &:hover {
            background: #ecf1f4;
            span {
              background: #ecf1f4;
            }
          }
        }
      }
    }
    .assessment-details {
      padding: 20px;
      &:hover {
        text-decoration: none !important;
      }
      width: 100%;
      p {
        margin-bottom: 0;
        line-height: 25px;
      }
      .first-row {
        flex-wrap: nowrap;
      }
      .first-row, .second-row, .third-row {
        @include flex-display;
        align-items: flex-start;
      }
      .second-row, .third-row {
        margin-top: 5px;
        flex-wrap: wrap;
      }
      .first-col {
        min-width: 36%;
        margin-right: 20px;
        #delete {
          display: none;
        }
      }
      .second-col {
        min-width: 80px;
        p {
          h5 {
            display: inline;
          }
        }
        .stat-icon {
          margin-right: 12px;
        }
      }
      .third-col {
        min-width: 10%;
        margin-right: 18px;
        #delete {
          display: none;
        }
      }
      .first-row, .second-row {
        .third-col {
          margin-left: auto;
          @media only screen and (min-width: 768px) {
            text-align: right;
          }
          margin-right: 0;
          min-width: unset;
        }
      }
      .fourth-col {
        margin-left: auto;
        margin-right: 32px;
      }
      .flat-pill {
        position: absolute;
        top: -7px;
        padding-bottom: 8px;
        &:nth-child(4) {
          left: 20px;
        }
        &:nth-child(5) {
          left: 80px;
        }
        &:nth-child(6) {
          left: 140px;
        }
      }
      .third-row {
        h5 {
          color: #0e0e2c;
        }
        & > div {
          @include flex-display;
        }
        .first-col img {
          margin: 0 4px;
        }
        .third-col {
          margin-left: auto;
          flex-wrap: wrap;
        }
      }
    }
  }
  .assessment-card:hover {
    text-decoration: none !important;
  }
  .assessment-card-enter {
    opacity: 0.01;
    height: 0px;
  }
  .assessment-card-enter-active {
    opacity: 1;
    height: var(--assessment-card-height);
    transition: opacity 400ms ease 400ms, height 300ms ease;
  }
  .assessment-card-exit {
    opacity: 1;
    height: var(--assessment-card-height);
  }
  .assessment-card-exit-active {
    opacity: 0.01;
    height: 0px;
    transition: opacity 300ms ease, height 400ms ease 300ms;
  }
  @media only screen and (max-width: 500px) {
    .heading, .first-row, .second-row, .third-row {
      flex-direction: column;
    }
    .third-row .third-col {
      margin-left: 0!important;
    }
  }
  @media only screen and (max-width: 768px) {
    .assessment-card {
      .actions {
        .dropdown {
          top: 50px;
          right: 5px;
        }
      }
    }
  }
  @media only screen and (min-width: 769px) {
    .assessment-card {
      .actions {
        position: absolute;
        right: 20px;
        .dropdown {
          position: absolute;
          background: #fff;
          top: 35px;
          right: -15px;
        }
      }
      .metrics {
        .role-location {
          margin-right: 205px;
        }
      }
    }
  }
  @include respond(tablet) {
    .lower-container,
    .upper-container {
      display: block !important;
      flex-direction: column;
    }
    .assessment-card {
      display: flex;
      flex-direction: column;
      .metrics {
        width: 100%;
        .first-col,
        .second-col,
        .third-col {
          width: 100%;
        }
        .second-col,
        .third-col {
          margin-top: 12px;
        }
        .first-col {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: nowrap;
          #delete {
            display: inline-block;
            margin-top: 0;
          }
          .stat-icon:not(:first-child) {
            margin-top: 16px;
          }
        }
      }
      .actions {
        @include flex-display;
        width: 100%;
        margin-top: 12px;
        justify-content: space-between;
        .stat-icon,
        .copy-field {
          margin: 0% 10%;
        }
        #delete {
          display: none;
        }
        .dropdown {
          top: 50px;
          bottom: unset;
        }
      }
    }
  }
  @include respond(laptop-strict) {
    .assessment-card {
      .metrics {
        .second-col {
          text-align: left;
        }
        .third-col {
          justify-content: space-between;
          display: flex;
        }
        .upper-container {
          .third-col {
            width: 100%;
            justify-content: flex-end;
            margin-right: 0;
          }
        }
      }
    }
  }
}
