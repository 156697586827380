@import './src/styles/fonts.styles.scss';
@import './src/styles/scroll.scss';

.add-interview-topic-modal.modal-container {
  max-height: 80vh;
  .modal-content {
    padding-bottom: 0;
    border-bottom: 2px solid $line;
    overflow-y: hidden !important;
  }
  .questions-modal-header {
    border-bottom: 2px solid $line;
    padding: 10px;
    h2 {
      color: $dark;
      font-size: 20px;
    }
    small {
      color: $slate;
    }
  }
  .actions-buttons {
    padding-top: 25px;
  }
  .skill-list-section, .question-list-section {
    display: flex;
    max-height: 50vh;
    .skills, .questions {
      @include subtle-scroll;
      overflow-y: auto;
      scrollbar-width: thin;
      padding: 10px 0;
      width: 30%;
      min-width: 30%;
      min-height: 100%;
      border-right: 2px solid $line;
      background-color: $light-cloud;
      .skills-item, .questions-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 10px;
        margin: 10px;
        background-color: white;
        color: $blue;
        .item-text {
          word-wrap: break-word;
          max-width: 80%;
        }
        &.selected {
          background: $mainBackground;
          color: $dark;
        }
        .alooba-checkbox {
          margin-top: -22px;
          margin-left: 10px;
        }
      }
    }
    .question-details {
      padding-top: 20px;
    }
    .question-details-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h4 {
        margin-bottom: 0;
      }
    }
    .details {
      @include subtle-scroll;
      overflow-y: auto;
      scrollbar-width: thin;
      width: 100%;
      min-height: 300px;
      padding-bottom: 20px;
      .select-skill-message {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
          margin-top: 20px;
        }
      }
      .skill-details {
        padding: 10px;
        .skill-details-title {
          color: $dark;
          font-weight: bold;
          font-size: 20px;
          padding: 10px 0;
        }
        .skill-details-description {
          color: $slate;
        }
      }
    }
  }
}
