@import './src/styles/colors.scss';

.sub-header {
  height: 70px;
  background: $white;
  width: 100%;
  z-index: 2;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  > .header-content {
    height: 26px;
    padding: 21px 16px 10px 60px;
    display: flex;
    align-items: center;
  }

  .back-chevron {
    margin: 4px 16px 0 0;
  }
  .back-chevron::before {
    content: '';
    border-style: solid;
    border-width: 3px 3px 0 0;
    border-color: $blue;
    cursor: pointer;
    height: 7px;
    width: 7px;
    transform: rotate(-135deg);
    display: inline-block;
    position: relative;
    float: left;
    top: -2px;
    vertical-align: top;
  }

  .page-title {
    display: inline-block;
    font-size: 22px;
    line-height: 26px;
    text-transform: uppercase;
    color: $blue;
  }

  .breadcrumbs {
    font-size: 22px;
    line-height: 26px;
    display: flex;
    align-items: center;
    .divider {
      margin: 0px 10px;
      font-weight: normal;
      color: $slate;
    }
    .inactive {
      color: $dark;
      font-weight: bold;
    }
    .editable {
      color: $blue;
      font-weight: normal;
    }
  }
}