@import 'styles/responsiveness';
.close-bar {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: #fff;
  }
}
.custom-banner {
  display: flex;
  border-radius: 4px;
  padding: 16px;
  .text-content {
    p {
      color: #eee;
      text-align: center;
    }
  }
}
.custom-alert {
  display: flex;
  border: 1px solid #F68C3E;
  border-radius: 6px;
  background: #faf3ee;
  position: relative;
  margin-bottom: 8px;
 .email-icon {
    border-right: 1px solid #F68C3E;
    padding: 8px 16px;
    img {
      height: 50px;
    }
  }
  .close-bar {
    background: #F68C3E;
  }
  .text-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 100px);
    padding: 16px;
    p {
      font-size: 16px;
      width: calc(100% - 180px);
    }
    @include respond(tablet){
      flex-direction: column;
      align-items: flex-start;
      .secondary {
        margin-top: 16px;
      }

      p {
        width: auto;
      }
    }
  }
}
#book-demo {
  background: #FFFFFF;
  position: relative;
  border: 1px solid #8C8CA1;
  box-sizing: border-box;
  box-shadow: 0px 0px 25px rgba(0, 67, 133, 0.1);
  border-radius: 5px;
  display: flex;
  margin-bottom: 16px;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  .close-bar {
    background: #004385;
  }
  p, a {
    font-size: 16px;
  }
  @include respond(tablet){
    flex-direction: column;
    align-items: flex-start;
    .sub-primary {
      margin-top: 16px;
    }
  }
}
.main-no-menu {
  #email-verification {
    margin:90px 50px 0px 50px;
  }
  #book-demo {
    margin:90px 50px -40px 50px;
  }
  #email-verification + #book-demo{
    margin-top: 8px;
  }
}