div.subscription-modal {
    h2 {
        margin-bottom: 28px;
    }
    .enjoy-trial {
        margin-bottom: 12px;
        margin-top: -12px;
        line-height: 2.4;
        font-weight: 600;
    }
    .custom-pricing {
        text-align: center;
        h3 {
            font-size: 32px;
            margin-bottom: 16px;
        }
        p {
            font-size: 18px;
            margin-bottom: 28px;
        }
    }
    .payment-options {
        .payment-option {
            display: flex;
            align-items: center;
            justify-content: space-around;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            padding: 14px;
            font-size: 18px;
            text-align: center;
            margin-bottom: 16px;
            .period {
                text-align: left;
                font-weight: bold;
                width: 100px;
                padding: 10px;
            }
            .credits {
                width: 260px;
            }
            .billing-period {
                width: 100px;
            }
            .price {
                width: 130px;
            }
            &.selectable {
                cursor: pointer;
            }
        }
        .disclaimer {
            margin-bottom: 16px;
            font-size: 12px;
            color: #888;
        }
    }
    .modal-footer {
        border-top: 1px solid #e0e0e0;
        padding-top: 28px;
        .modal-footer-actions {
            display: flex;
            justify-content: center;
            margin-top: 28px;
            .primary {
                margin-left: 14px;
            }
        }
    }
}