$blue: #004385;
$dark: #0e0e2c;
$success: #03cea4;
$link: #004385;
$grey: #4a4a68;
$orange: #f68c3e;
$pink: #eb08a7;
$light-orange: #fffbed;
$disabled: #8c8ca1;
$slate: #8c8ca1;
$error: #d62828;
$mainBackground: #ecf1f4;
$light-border: #ecf1f4;
$light-cloud: #fafafa;
$medium-grey: #f1f1f1;
$hover: #fafafa;
$line: #dedfe1;
$white: #ffffff;
$selected: #2684ff;
