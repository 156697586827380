.formModal-container {
  z-index: 101;
  display: flex;
  justify-content: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  align-items: center;
  
  p {
    margin-top: 24px;
  }
}

.formModal-form {
  border: 1px solid #ECF1F4;
  border-radius: 8px;
  background: #fff;
  padding: 30px;
  
  .radio-buttons {
    margin-top: 24px;
  }
  
  .button-row {
    text-align: center;
    Button {
      display: inline-block;
      margin: 24px 5px 0px 5px;
    }
  }
  .hint {
    font-size: 13px;
  }
  .upgrade-subscription {
    border: 1px solid #004385;
    border-radius: 8px;
    margin: 30px 0 0;
    padding: 0 15px 15px;
    background-color: #EDF9FF
  }
}