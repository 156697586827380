#hiringTeam {
  position: relative;
  hr {
    border: 1px solid #ECF1F4;
    margin: 16px 0;
  }
  .intro {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .desc {
      width: 100%;
    }
  }
  .alooba-table {
    padding-bottom: 60px;
  }
}
.formModal-form {
  width: 450px;
}
