@import '../../../styles/colors.scss';
@mixin flex-display {
  display: flex;
  align-items: center;
}
.dropdown_select {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .css-vj8t7z,
  .css-vj8t7z:hover,
  .css-2o5izw {
    margin-top: 5px;
    outline: none;
    border-color: unset;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #ecf1f4;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 2px 10px;
    background: #fff;
  }
  .css-15k3avv {
    .css-11unzgr,
    .css-11unzgr:active {
      .css-z5z6cw,
      .css-z5z6cw:active {
        background: #fff;
        border: 1px solid #fafafa;
        color: #4a4a68;
      }
    }
  }
  .form-field {
    .dropdown___control {
      border: 1px solid #ecf1f4;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
      height: 42px;
    }
  }
}
.add_button {
  min-width: 100%;
  .css-vj8t7z,
  .css-vj8t7z:hover,
  .css-2o5izw {
    outline: none;
    border-color: unset;
    box-shadow: unset;
    border: 1px solid #ecf1f4;
    box-sizing: border-box;
    border-radius: 10px;
    min-height: 30px;
    max-height: 30px;
    padding: 0 !important;
    background: #fff;
    .css-1492t68 {
      top: 40%;
    }
    .css-1wy0on6 {
      display: none;
    }
    .css-1g6gooi {
      input {
      }
    }
  }
  .css-15k3avv {
    min-width: 300px;
    width: max-content;
    .css-11unzgr,
    .css-11unzgr:active {
      .css-z5z6cw,
      .css-z5z6cw:active {
        background: #fff;
        border: 1px solid #fafafa;
        color: #222;
      }
    }
  }
  .placeholder_value {
    display: flex;
    align-items: center;
    .icon {
      padding: unset;
      margin-right: 12px;
    }
  }
}
.action-menu-wrapper {
  padding-top: 6px;
  padding-bottom: 16px;
  padding-left: 16px;
  margin-left: -16px;
  margin-bottom: -10px;
  position: relative;
  .with-tooltip {
    z-index: 8;
  }
  .hitbox {
    z-index: 7;
    position: absolute;
    top: -10px;
    right: 0px;
    width: 90px;
    height: 60px;
    background-color: #00000001;
  }
}
.action-menu-portal {
  z-index: 30;
  width: 300px;
}
.action-menu {
  position: absolute;
  top: 23px;
  right: 0px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #dedfe1;
  z-index: 31;
  text-align: left;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  p {
    background: #fff;
    border-radius: 5px;
    padding: 4px 20px;
    color: #103662;
    cursor: pointer;
    .icon {
      padding: 8px 8px 8px 0 !important;
      width: 20px;
      height: 1.2em;
      color: $blue;
    }
    span {
      color: #103662;
    }
    @include flex-display;
    justify-content: flex-start;
    &:not(.disabled):hover {
      background: #ecf1f4;
      span {
        background: #ecf1f4;
      }
    }
    &.disabled {
      span {
        color: #90B6E2;
      }
    }
  }
}
