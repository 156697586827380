@import '../../styles/colors.scss';

div.interview-configuration {
  padding: 0px;

  .top-section {
    padding: 20px 15px;
    height: 35px;
    border-bottom: 1px solid $line;
    display: flex;
    justify-content: space-between;
  }

  .content-wrapper {
    display: flex;
  }

  .estimated-duration {
    display: flex;
    align-items: center;
    background: $mainBackground;
    padding: 15px 20px;
    margin-bottom: -1px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    color: $grey;
    &.warning {
      background-color: $light-orange;
    }
    .estimated-duration-value {
      margin: 0 10px;
      color: $dark;
    }
    .clock-icon {
      margin-right: 10px;
    }
    .info-icon {
      margin: 0 10px 0 5px;
    }
  }
}

.interview-question-details {
  .question-details-heading {
    display: flex;
    justify-content: space-between;
  }
  .collapsable-section-title {
    display: flex;
    justify-content: space-between;
  }
}

.interview-name-input {
  margin-top: 0;
  display: flex;
  align-items: center;
}

.interview-name-length {
  font-size: 14px;
  font-weight: lighter;
  line-height: 1.5;
  margin-left: 10px;
}

.loading-icon {
  margin-left: 4px;
  color: $blue;
}

.interview-name-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.interview-name-edit-icon {
  margin-left: 10px;
}
