@import "styles/colors.scss";

button {
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border: none;
  .MuiBadge-root {
    margin-top: -23%;
  }
  .icon {
    padding: 0;
    padding-right: 12px;
  }
  &:hover {
    text-decoration: none;
  }
  &:hover:not([disabled]) {
    transform: scale(1.03);
  }
  &:disabled {
    cursor: not-allowed;
    &.loading {
      cursor: wait;
    }
  }
  &.disabled {
    background: #ecf1f4;
    cursor: not-allowed;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    color: #8c8ca1;
  }
  &.right {
    float: right;
    margin-left: 5px;
  }
  &.center {
    display: block;
    margin: 0 auto;
  }
  &.primary {
    font-weight: bold;
    background: #004385;
  }
  &.secondary {
    background: #fafafa;
    border: 1px solid #f68c3e;
    color: #f68c3e;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  }
  &.inverted-secondary {
    background: #f68c3e;
    color: #fff;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  }

  &.sub-primary {
    background: #fafafa;
    border: 1px solid #004385;
    color: #004385;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  }
  &.ghost {
    background: #fff;
    display: inline-block;
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid #ecf1f4;
    color: #222;
    svg {
      color: #8c8ca1;
    }
  }
  &.lg {
    font-size: 16px;
    min-width: 150px;
    padding: 10px 16px;
  }
  &.md {
    font-size: 14px;
    min-width: 120px;
    padding: 8px 12px;
  }
  &.sm {
    font-size: 12px;
    min-width: 100px;
    padding: 8px;
  }
}
.text-button {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  .text-button-internal {
    margin: 0 5px 0 10px;
    &.add {
      color: $blue;
    }
    &.delete {
      color: $error;
    }
  }
}