@import './src/styles/colors.scss';

.interview-candidates {
  margin-top: 20px;
  display: block;
  h2 {
    font-size: 20px;
  }
  .participants-container {
    margin-top: 14px;
  }
  table.dynamic-table {
    tbody {
      tr.dynamic-table-row {
        td.Name {
          color: $blue;
        }
      }
      tr.dynamic-table-row:hover {
        color: $orange;
        td {
          color: $orange;
        }
        .dynamic-table-cell a {
          color: $orange;
        }
      }
    }
  }
}
