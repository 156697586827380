@import '../../../styles/colors.scss';
@import '../../../styles/spacing.styles.scss';
@import '../../../styles/responsiveness.scss';
@import 'react-image-lightbox/style.css';
.space-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.flex-end {
    justify-content: flex-end;
}
.grid {
    display: grid;

    &.grid-3 {
        grid-template-columns: repeat(3, 1fr);
        @include respond(tablet) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include respond(phone) {
            grid-template-columns: repeat(1, 1fr);
        }

    }

    &.grid-2 {
        grid-template-columns: repeat(2, 1fr);
    }

    &.cols-md-2 {
        @include respond(laptop) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include respond(desktop) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &.cols-sm-1 {
        @include respond(phone) {
            grid-template-columns: repeat(1, 1fr) ;
        }
        @include respond(tablet) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &.cols-sm-2 {
        @include respond(phone) {
            grid-template-columns: repeat(2, 1fr) ;
        }
        @include respond(tablet) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

.candidate-details .candidate-message {
    p, a {
        font-size: 20px;
        line-height: 28px;
    }
    p {
        margin: 10px 0px 20px;
    }
}

.candidate-information {

    #invited-at, #submitted-at, #assessment-status {
        padding-left: 5px;
    }
    @include respond(laptop) {
        flex-direction: column;
        .w50 {
            width: 100%;
        }
        #invited-at, #submitted-at, #assessment-status {
            display: block;
            margin-top: 0px;
            margin-bottom: 20px;
            padding-left: 0px;
            margin-left: 26px;
        }
        .personal-info-icon {
            vertical-align: top;
        }
    }

    @include respond(phone) {
        .w50 {
            width: 100%;
        }
        #invited-at, #submitted-at, #assessment-status {
            display: block;
            margin-top: 4px;
            margin-bottom: 20px;
            margin-left: 28px;
        }
    }
    .inviter-deleted {
        color: $disabled;
    }
}
.alooba-input.alooba-switch {
    margin-top: 0px;
}

.hiring-status-container {
    display: flex;
    align-items: center;
    .information-field {
        margin-right: 55px;

        @include respond(phone) {
            margin-right: 5px;
        }
    }
    .information-field-value {
        width: 250px;
        .MuiInputBase-root {
            padding-left: 5px;
        }
        @include respond(phone) {
            width: 200px;
            padding-left: 0px;
        }
    }
}

.candidate-details {
    hr {
        width: 100%;
    }
    .candidate-details-header {
        display: flex;
        justify-content: space-between;
    }
}
.information-fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .information-field-container {
        display: flex;
        width: 50%;
        @include respond(phone) {
            width: 100%;
            flex-direction: column;
        }
        .information-field {
            color: $slate;
            @include respond(desktop) {
                width: 20%;
                padding-right: 10px;
            }
            @include respond(laptop) {
                width: 30%;
            }
            @include respond(tablet) {
                width: 30%;
                min-width: 100px;
            }
            @include respond(phone) {
                width: 100%;
            }

        }

        .information-field-value {
            width: auto;
            color: $dark;
            margin-left: 10px;


            @include respond(tablet) {
                margin-left: 10px;
            }
            @include respond(phone) {
                margin-top: 8px;
                margin-left: 0px;
                .personal-info-icon {
                    margin-top: -5px;
                    margin-right: 1px;
                    width: auto;
                }
            }
        }
    }
}

.information-field-value {
    @include respond(phone) {
        margin-top: 8px;
    }
}
.score-boxes {
    .score-box:last-child {
        margin-right: 0;
    }
}
.score-boxes {
    margin: 10px 0px 30px 0;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
    .score-box {
        margin-right: 12px;
        flex-basis: 33%;
        border: 1px solid $line;
        border-radius: 5px;
        min-width: 200px;
        max-width: 400px;
        h3 {
            font-size: 20px;
            margin-bottom: 6px;
        }
        .score-box-content {
            margin-top: 18px;
            border-radius: 5px;
            border: 1px solid #dedfe1;
            padding: 18px 20px;
            min-height: 63px;
        }
        .score {
            h2 {
                font-weight: bold;
                font-size: 28px;
                color: $blue;
                margin-top: 14px;
            }
            .percentile-text {
                align-items: flex-start;
                display: inline-flex;
                line-height: 1;
            }
            .percentile-ordinal-indicator {
                font-size: 17px;
            }
        }
        @include respond(phone) {
            flex-basis: 100%;
        }
    }
    .score-box .border-success {
        border: 1px solid $blue;
    }
    .score-box .border-warning {
        border: 1px solid orange;
    }
    .score-box:not(.overall-score) {
        border: 0px;
    }

    &.Expired, &.Invited {
        display: none;
    }
}
.score-boxes:first-child {
    padding-left: 0;
}

.subject-scores {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 12px;
    margin-bottom: 30px;
}
.subject-scores  .subject-score {
    margin-top: 12px;
    width: 25%;
    @media screen and (max-width: 768px) {
        width: 50%;
    }
}
.subject-score > * {
    text-align: center;
}
.scored-percent {
    font-size: 24px;
    font-weight: 700;
}
.subject-scores .score {
    margin-top: 10px;
}

.text-slate {
    color: $slate;
}

.text-dark {
    color: $dark;
}

.personal-info-icon, .assessment-status-icon {
    vertical-align: middle;
    height: 16px;
    width: 27px;
}

@include respond(phone) {
    .notes-count-indicator {
        margin-top: 15px;
    }
}

@include respond(tablet) {
    .md-display-block {
        display: block;
    }
}
.show-when-cloaked {
    display: none;
}
.cloaked {
    .cloakable {
        display: none;
    }
    .show-when-cloaked {
        display: inherit;
    }
}
.suspicious-activity-card {
    background: rgba($orange, 0.1);
    padding: 20px;
    margin-top: 15px;
    .category-details {
        margin-left: 16px;
        margin-top: 10px;
    }
    .suspicious-activity {
        margin-top: 12px;
    }
    .category-header {
        font-size: 20px;
    }
    .hint {
        color: $grey;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    h4:first-of-type {
        margin-top: 0px;
    }
    h4 {
        margin-bottom: 11px;
        margin-top: 20px;
    }

    &>div {
        margin-bottom: 15px;
    }
}
.other-assessment-cards {
    color: $dark;
    display: grid;
    column-gap: 25px;
    row-gap: 21px;

    .main-card-content, .card-footer {
        padding: 20px
    }
    .card-footer {
        background-color: $mainBackground;
    }
    .main-container {
        padding: 0;
    }
    .main-card-content {
        min-height: 120px;
    }
    @include respond(desktop) {
        grid-template-columns: repeat(3, 1fr);
    }
    @include respond(laptop) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include respond(tablet) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include respond(phone) {
        grid-template-columns: repeat(1, 1fr);
        .main-card-content {
            min-height: 0px;
        }
    }
    .other-assessment-title {
        font-size: 16px;
    }
    .warning-icon {
        margin-top: 16px;
        margin-right: 20px;
        float: right;
        height: 24px;
        width: 24px;
    }
    .assessment-status-icon {
        margin-right: 3px;
    }
}


.paginator {
    align-items: center;
    display: flex;
    justify-content: center;
    button {
        font-size: 16px;
        color: $blue;
        &::after {
            content: '...';
            color: $blue;
        }
    }
}

.other-assessment-title {
    font-weight: bold;
}
.cloak-toggler {
    margin-top: -40px;
}

hr {
    width: 100%;
}

.candidate-snapshots-container {
    margin-top: 24px;
    .candidate-snapshots-carousel-container {
        background-color: $mainBackground;
        margin-top: 10px;
        padding: 5px 45px 0px 45px;
        border-radius: 10px;
        .carousel {
            .slider {
                margin-top: 0px;
                img {
                    width: 100px;
                    cursor: pointer;
                }
            }
        }
        .candidate-snapshot {
            position: relative;
            .snapshot-icon {
                .with-tooltip {
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    background: white;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 5px 5px;
                    img {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }
}

.candidate-scores-wrapper {
    display: flex;
}
.candidate-position-buckets {
    display: flex;
    height: 30px;
    width: 100%;
    align-items: flex-end;
    margin-top: 6px;
    background-color: #FAFAFA;
    .bucket {
        width: 10%;
    }
}
@media only screen and (min-width: 1201px) {
    .cloak-toggler {
        margin-top: -20px;
    }
}