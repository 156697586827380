@import '../../../../styles/colors.scss';

.rating-question {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;

  label {
    display: block;
    width: 120px;
    height: 120px;
    border: solid 1px $line;
    padding: 1px;
    margin: 5px 16px 5px 0;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    box-shadow: 0px 2px 1px 0px #0000000d;
    transition: box-shadow 80ms;
    h5 {
      font-weight: normal;
    }
    &:hover {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    }

    [type='radio'] {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    }

    img {
      margin: 18px auto;
      display: block;
    }

    &.checked {
      border: solid 2px $blue;
      padding: 0px;
      h5 {
        font-weight: bold;
      }
    }
  }
}
