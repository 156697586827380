.copylink-container {
    width: 100%;
    display: flex;

    input {
        outline: none;
        margin-right: 20px;
        border: 1px solid #ECF1F4;
        box-shadow: 0px 2px 3px 0px #0000001A;
        border-radius: 5px;
        background-color: #FAFAFA;
        width: 100%;
        padding: 8px;
    }
}