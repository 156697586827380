@import "styles/colors.scss";

.datetime-picker-wrapper{
    width: 100%;
    .errorMsg {
        color: $error;
        font-size: 12px;
    }
}
.react-datepicker__navigation{
    box-shadow: none;
}