@import "../../../styles/colors.scss";
@import "../../../styles/responsiveness";
.test-tab-timeline {
  .timeline {
    @include respond(phone) {
      min-height: 100px;
      width: 100% !important;
    }
    &>svg {
      margin-top: -30px;
      overflow: visible;
    }
    &.expanded {
      &>svg {
        margin-top: 0px;
      }
    }

    .timeline-point {
      stroke: rgb(255, 255, 255);
      stroke-width: 2;
      fill-opacity: 0.5;
    }
    .timeline-icon-bg {
      stroke: rgb(255, 255, 255);
      stroke-width: 3;
    }
    .timeline-icon {
      opacity: 0.6;
    }

  }
  .timeline-event-switch-controls {
    .timeline-event-switch {
      margin-right: 5em;
    }
    .controls {
      background: $medium-grey;
      font-size: .9rem;
      padding: 8px 0px 8px 20px;
      margin: 10px 20px 10px 0;
    }
  }
}