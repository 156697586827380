.video-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.video-container.multiple{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .ivs-player {
    flex: 1;
    height: 200px;
    max-width: 400px;
    margin-bottom: 10px;
  }
}
.ivs-player {
  width: 80%;
  height: 80%;
  video::-webkit-media-controls-timeline,
  video::-webkit-media-controls-current-time-display {
    display: none;
  }
}