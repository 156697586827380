@import '../../../styles/colors.scss';

.manage-credits-page {
    margin-top: 30px;
    h2 {
        font-size: 20px;
        margin-top: 24px;
        margin-bottom: 16px;
    }

    p.introduction, p.auto-topup {
        font-size: 15px;
        margin-bottom: 8px;
        margin-top: 10px;
        font-weight: 600;
    }

    @media (max-width: 1023px) {
        margin-top: 70px;
    }

    .auto-topup {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px;

    }
    span.MuiSwitch-track {
        background-color: $grey !important;
    }
}