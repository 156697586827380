.autocomplete-container.focused {
    border-bottom: none;
    box-shadow: 0 0 4px #2684FF;
    border-color: #2684FF;
    border: 1px solid #2684FF;
}
.autocomplete-container {
    position: absolute;
    width: 100%;
    margin-bottom: 80px;
    border-radius: 4px;
    border: 1px solid #DEDFE1;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .autocomplete-input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    .autocomplete-input {
      width: 100%;
      padding: 10px 35px 10px 10px;
      border: none;
      border-radius: 4px 4px 0 0;
      border-bottom: none;
      appearance: none;
      font-size: 16px;
      outline: none;
      transition: box-shadow 0.2s ease-in-out;
      box-sizing: border-box;
      border-radius: 4px;
        &:focus {
           outline: none;
        }
    }
  
    .autocomplete-clear-btn {
      position: absolute;
      right: 10px;
      border: none;
      background: none;
      font-size: 16px;
      color: #aaa;
      cursor: pointer;
      outline: none;
    
      &:hover {
        color: #000;
      }
    }  
  }
  
  .autocomplete-dropdown-wrapper {
      .autocomplete-dropdown {
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        max-height: 240px;
        overflow-y: auto;
        box-sizing: border-box;
        border-top: none;
        border-radius: 0 0 4px 4px;
    }
    hr {
        width: calc(100% - 20px);
        color: #DFDFDF;
        margin: auto;
    }
  }
  
  .autocomplete-option {
    padding: 10px;
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.2s;

    &:hover,
    &.active {
      background-color: #f0f0f0;
    }
  }
  
  .autocomplete-spinner {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-top: 2px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    transform: translateY(-50%);
  }