.m1 {
  margin: 4px;
}
.m2 {
  margin: 8px;
}
.m3 {
  margin: 12px;
}
.m4 {
  margin: 16px;
}
.m5 {
  margin: 20px;
}
.m6 {
  margin: 24px;
}
.m7 {
  margin: 28px;
}
.m8 {
  margin: 32px;
}
.m9 {
  margin: 42px;
}
.m10 {
  margin: 48px;
}
.m11 {
  margin: 56px;
}
.m12 {
  margin: 64px;
}

.mt1 {
  margin-top: 4px;
}
.mt2 {
  margin-top: 8px;
}
.mt3 {
  margin-top: 12px;
}
.mt4 {
  margin-top: 16px;
}
.mt5 {
  margin-top: 20px;
}
.mt6 {
  margin-top: 24px;
}
.mt7 {
  margin-top: 28px;
}
.mt8 {
  margin-top: 32px;
}
.mt9 {
  margin-top: 42px;
}
.mt10 {
  margin-top: 48px;
}
.mt11 {
  margin-top: 56px;
}
.mt12 {
  margin-top: 64px;
}

.mb1 {
  margin-bottom: 4px;
}
.mb2 {
  margin-bottom: 8px;
}
.mb3 {
  margin-bottom: 12px;
}
.mb4 {
  margin-bottom: 16px;
}
.mb5 {
  margin-bottom: 20px;
}
.mb6 {
  margin-bottom: 24px;
}
.mb7 {
  margin-bottom: 28px;
}
.mb8 {
  margin-bottom: 32px;
}
.mb9 {
  margin-bottom: 42px;
}
.mb10 {
  margin-bottom: 48px;
}
.mb11 {
  margin-bottom: 56px;
}
.mb12 {
  margin-bottom: 64px;
}

.mx1 {
  margin-right: 4px;
  margin-left: 4px;
}
.mx2 {
  margin-right: 8px;
  margin-left: 8px;
}
.mx3 {
  margin-right: 12px;
  margin-left: 12px;
}
.mx4 {
  margin-right: 16px;
  margin-left: 16px;
}
.mx5 {
  margin-right: 20px;
  margin-left: 20px;
}
.mx6 {
  margin-right: 24px;
  margin-left: 24px;
}
.mx7 {
  margin-right: 28px;
  margin-left: 28px;
}
.mx8 {
  margin-right: 32px;
  margin-left: 32px;
}
.mx9 {
  margin-right: 42px;
  margin-left: 42px;
}
.mx10 {
  margin-right: 48px;
  margin-left: 48px;
}
.mx11 {
  margin-right: 56px;
  margin-left: 56px;
}
.mx12 {
  margin-right: 64px;
  margin-left: 64px;
}

.mr1 {
  margin-right: 4px;
}
.mr2 {
  margin-right: 8px;
}
.mr3 {
  margin-right: 12px;
}
.mr4 {
  margin-right: 16px;
}
.mr5 {
  margin-right: 20px;
}
.mr6 {
  margin-right: 24px;
}
.mr7 {
  margin-right: 28px;
}
.mr8 {
  margin-right: 32px;
}
.mr9 {
  margin-right: 42px;
}
.mr10 {
  margin-right: 48px;
}
.mr11 {
  margin-right: 56px;
}
.mr12 {
  margin-right: 64px;
}

.ml1 {
  margin-left: 4px;
}
.ml2 {
  margin-left: 8px;
}
.ml3 {
  margin-left: 12px;
}
.ml4 {
  margin-left: 16px;
}
.ml5 {
  margin-left: 20px;
}
.ml6 {
  margin-left: 24px;
}
.ml7 {
  margin-left: 28px;
}
.ml8 {
  margin-left: 32px;
}
.ml9 {
  margin-left: 42px;
}
.ml10 {
  margin-left: 48px;
}
.ml11 {
  margin-left: 56px;
}
.ml12 {
  margin-left: 64px;
}

.my1 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.my2 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.my3 {
  margin-top: 12px;
  margin-bottom: 12px;
}
.my4 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.my5 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.my6 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.my7 {
  margin-top: 28px;
  margin-bottom: 28px;
}
.my8 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.my9 {
  margin-top: 42px;
  margin-bottom: 42px;
}
.my10 {
  margin-top: 48px;
  margin-bottom: 48px;
}
.my11 {
  margin-top: 56px;
  margin-bottom: 56px;
}
.my12 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.p1 {
  padding: 4px;
}
.p2 {
  padding: 8px;
}
.p3 {
  padding: 12px;
}
.p4 {
  padding: 16px;
}
.p5 {
  padding: 20px;
}
.p6 {
  padding: 24px;
}
.p7 {
  padding: 28px;
}
.p8 {
  padding: 32px;
}
.p9 {
  padding: 42px;
}
.p10 {
  padding: 48px;
}
.p11 {
  padding: 56px;
}
.p12 {
  padding: 64px;
}

.pt1 {
  padding-top: 4px;
}
.pt2 {
  padding-top: 8px;
}
.pt3 {
  padding-top: 12px;
}
.pt4 {
  padding-top: 16px;
}
.pt5 {
  padding-top: 20px;
}
.pt6 {
  padding-top: 24px;
}
.pt7 {
  padding-top: 28px;
}
.pt8 {
  padding-top: 32px;
}
.pt9 {
  padding-top: 42px;
}
.pt10 {
  padding-top: 48px;
}
.pt11 {
  padding-top: 56px;
}
.pt12 {
  padding-top: 64px;
}

.pb1 {
  padding-bottom: 4px;
}
.pb2 {
  padding-bottom: 8px;
}
.pb3 {
  padding-bottom: 12px;
}
.pb4 {
  padding-bottom: 16px;
}
.pb5 {
  padding-bottom: 20px;
}
.pb6 {
  padding-bottom: 24px;
}
.pb7 {
  padding-bottom: 28px;
}
.pb8 {
  padding-bottom: 32px;
}
.pb9 {
  padding-bottom: 42px;
}
.pb10 {
  padding-bottom: 48px;
}
.pb11 {
  padding-bottom: 56px;
}
.pb12 {
  padding-bottom: 64px;
}

.px1 {
  padding-left: 4px;
  padding-right: 4px;
}
.px2 {
  padding-left: 8px;
  padding-right: 8px;
}
.px3 {
  padding-left: 12px;
  padding-right: 12px;
}
.px4 {
  padding-left: 16px;
  padding-right: 16px;
}
.px5 {
  padding-left: 20px;
  padding-right: 20px;
}
.px6 {
  padding-left: 24px;
  padding-right: 24px;
}
.px7 {
  padding-left: 28px;
  padding-right: 28px;
}
.px8 {
  padding-left: 32px;
  padding-right: 32px;
}
.px9 {
  padding-left: 42px;
  padding-right: 42px;
}
.px10 {
  padding-left: 48px;
  padding-right: 48px;
}
.px11 {
  padding-left: 56px;
  padding-right: 56px;
}
.px12 {
  padding-left: 64px;
  padding-right: 64px;
}

.py1 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.py2 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.py3 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.py4 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.py5 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py6 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.py7 {
  padding-top: 28px;
  padding-bottom: 28px;
}
.py8 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.py9 {
  padding-top: 42px;
  padding-bottom: 42px;
}
.py10 {
  padding-top: 48px;
  padding-bottom: 48px;
}
.py11 {
  padding-top: 56px;
  padding-bottom: 56px;
}
.py12 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.pr1 {
  padding-right: 4px;
}
.pr2 {
  padding-right: 8px;
}
.pr3 {
  padding-right: 12px;
}
.pr4 {
  padding-right: 16px;
}
.pr5 {
  padding-right: 20px;
}
.pr6 {
  padding-right: 24px;
}
.pr7 {
  padding-right: 28px;
}
.pr8 {
  padding-right: 32px;
}
.pr9 {
  padding-right: 42px;
}
.pr10 {
  padding-right: 48px;
}
.pr11 {
  padding-right: 56px;
}
.pr12 {
  padding-right: 64px;
}

.pl1 {
  padding-left: 4px;
}
.pl2 {
  padding-left: 8px;
}
.pl3 {
  padding-left: 12px;
}
.pl4 {
  padding-left: 16px;
}
.pl5 {
  padding-left: 20px;
}
.pl6 {
  padding-left: 24px;
}
.pl7 {
  padding-left: 28px;
}
.pl8 {
  padding-left: 32px;
}
.pl9 {
  padding-left: 42px;
}
.pl10 {
  padding-left: 48px;
}
.pl11 {
  padding-left: 56px;
}
.pl12 {
  padding-left: 64px;
}
.w50 {
  width: 50%;
}