
@import '../../styles/colors.scss';
@import '../../styles/responsiveness.scss';

.section-subtitle {
    font-size: 15px;
    color: $grey;
    font-weight: normal;
    margin-bottom: 14px
}
.empty-screen {
    text-align: center;
    padding: 50px;
    img {
        width: 3%;
    }
    .description {
        color: $dark;
    }
}
.feed-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .image-align {
        vertical-align: top;
    }
}

.questions-button-grid {
    display: flex;
    flex-wrap: wrap;
}
.questions-navigation {
    .subject-name {
        display: flex;

        h5 {
            font-family: "Nunito Sans", sans-serif;
            font-weight: bold;
        }
    }
    div.hr {
        flex-grow: 2;
        background-color: $line;
        margin-top: 8px;
        height: 1px;
        margin-left: 10px;
        margin-right: 20px;
    }
}
.question-details {
    grid-column: 2 / span 2;
    border-left: 1px solid $line;
    padding-left: 25px;
    h4 {
        margin-bottom: 22px;
        text-transform: uppercase;
        font-weight: bold;
        font-family: "Nunito Sans", sans-serif;
    }
    .question-details-meta {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        padding: 5px 0;
        .subjects {
            grid-column: 2 / span 5;
            .pill {
                padding: 0.2rem 0.8rem;
                border-radius: 1rem;
                background: $mainBackground;
                margin-right: 20px;
            }
        }
    }
    .question-background {
        margin-top: 20px;
        background-color: $blue;
        padding:17px 16px 21px 20px;
        color: white;
        border-left: 4px solid $orange;
        h1, h2, h3, h4, h5, h6 {
            color: white;
        }
        code {
            color: $dark;
        }
        p {
            color: white;
            img {
                max-width: 100%;
            }
        }
    }

    .question-background, .question-bad-response, .question-good-response, .question-model-response  {
        margin-bottom: 20px;
        h4 {
            margin-bottom: 10px;
        }
    }

    .response-box {
        color: $dark;
        background-color: $hover;
        padding: 13px 20px;
        word-wrap: anywhere;
        word-break: break-word;
        white-space: pre-wrap;

        img {
            max-width: 100%;
        }
    }

    .feed-controls {
        margin-top: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .score-input {
            display: flex;
            .label {
                color: $dark;
                font-weight: bold;
                padding: 5px 5px 5px 0px;
                margin-top: 1px;
                margin-right: 5px;
            }
        }

        @include respond(phone) {
            flex-wrap: wrap;
            .score-input {
                flex-wrap: wrap;
                width: 100%;
                margin-bottom: 15px;
            }
        }
        @include respond(tablet) {
            .score-input {
                width: 100%;
            }
        }

        @include respond(laptop) {
            .score-input {
                width: 260px;
            }
        }

        @include respond(desktop) {
            .score-input {
                margin-right: 15px;
            }
        }
   
    }
}
.reference {
    color: $slate;
}