@import '../../styles/colors.scss';

div.payment-methods {
    margin-bottom: 28px;
    h3 {
        margin-bottom: 12px;
    }
    .main-container {
        display: inline-flex;
        align-items: center;
        padding: 12px 16px;

        .icon {
            color: $blue;
            margin-right: 16px;
        }
        position: relative;
        .del-btn {
            position: absolute;
            top: 0;
            right: 0;
            color: gray;
        }
    }
}
