@import './src/styles/colors.scss';
@import './src/styles/scroll.scss';

.question-section {
  width: calc(100% - 380px);
  padding: 1em;
  word-break: break-word;
  height: 550px;
  overflow-y: auto;
  @include subtle-scroll;

  img.icon {
    height: 1em;
  }

  .question-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    .left {
      display: flex;
    }
    .right {
      display: flex;
    }
    .skill {
      font-weight: bold;
      margin-right: 10px;
    }
  }

  .question-text {
    border-left: 2px solid $orange;
    background: $blue;
    color: white;
    font-weight: bold;
    line-height: 22px;
    padding: 1em;
  }
}