@import "../../styles/colors";
@import "../../styles/responsiveness";

.nps-landing {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  .main-container {
    padding: 20px 0;
    margin: 0 20px;
    width: 750px;
    display: flex;
    align-items: center;
    flex-direction: column;
    h2 {
      padding: 0 20px;
    }
  }
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    gap: 50px;
  }
  .main-logo {
    height: 120px;
    display: block;
  }
  .g2-logo {
    height: 120px;
    display:block;
  }
  .ratings {
    margin: 0 20px;
  }
  .explanation {
    width: calc(100% - 40px);
  }
  .details {
    margin-top: 24px;
    font-size: 22px;
    max-width: 600px;
    padding: 0 24px;
  }
  .details>p:first-child {
    margin-bottom: 12px;
  }
  .actions {
    margin-top: 24px;
    text-align: center;
  }
  button.ignore-review {
    color: $link;
    display: block;
    text-align: center;
    width: 100%;
    margin-top: 10px;
    font-weight: bold;
  }
  .g2-review-video {
    margin-top: 30px;
    width: 800px;
    height: auto;
    aspect-ratio: 1.93;
    max-width: 95%;
  }
  iframe {
    border: none;
  }
  .write-review-button {
    margin-top: 20px;
    color: white;
    background-color: orangered;
    width: 200px;
    padding: 10px 18px;
    text-align: center;
    font-weight: bold;
  }
  .write-review-button.disabled {
    background-color: #ffcc99;
  }
  .rate-value {
    color: #004385;
  }
  .alooba-input {
    max-width: 800px;
    margin-bottom: 20px;
    width: 100%;
  }
  .radio-buttons {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .circular-radio-button {
      margin: 5px;
      cursor: pointer;
    }
  }
  .hint {
    color: #aaa;
  }
  .left {
    float: left;
  }
  .right {
  float: right;
  }
  .row {
    width: 100%;
  }
  h2 {
    text-align: center;
  }
}