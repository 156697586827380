@import '../../../styles/colors.scss';
.long-str-wrap {
  word-wrap: anywhere;
  word-break: break-word;
}
.question-response-section {
  font-family: 'Nunito Sans', sans-serif;
  margin-top: 20px;
  * {
    font-family: 'Nunito Sans', sans-serif;
  }
  .response-title {
    display: flex;
    flex-direction: row;
    margin-bottom: 11px;
    h4 {
      font-size: 16px;
      font-weight: 700;
      flex-grow: 1;
    }
    .right-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon {
        padding: 0;
      }
    }
  }
}
.grey-section {
  background-color: $hover;
  color: $dark;
  padding: 15px 16px;
  border-radius: 5px;
  img {
    max-width: 100%;
  }
}
.collapsable {
  cursor: pointer;
}
.candidate-response {
  background-color: #fff;
  border: 1px solid $mainBackground;
  padding: 15px 16px;
  border-radius: 5px;
  &.good, &.correct {
    border: 2px solid $success;
  }
  &.wrong, &.bad {
    border: 2px solid $error;
  }
  &.ok {
    border: 2px solid $orange;
  }
  &.evaluate {
    border: 2px solid $blue;
  }
  white-space: pre-wrap;
}
.total-test-score {
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  align-items: center;
  background-color: $mainBackground;
  width: 240px;
  height: 64px;
  border-radius: 32px;
  * {
    font-family: 'Nunito Sans', sans-serif;
  }
  .scored-percent {
    font-size: 16px;
    font-weight: 700;
  }
  .score-circle {
    margin-top: 5px;
  }
  .score-details {
    margin-left: 21px;
    .total-score-title {
      font-size: 14px;
      font-weight: 700;
    }
    h4 {
      color: $slate;
      span {
        color: $dark;
      }
    }
  }
}

.hidden {
  display: none;
}
.transcription-text {
  max-height: 300px;
  overflow-y: auto;
}
.test-question-details-wrapper {
  width: 100%;
}

.candidate-question-button img {
  max-width: 20px;
  &.rating {
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.2));
  }
}
