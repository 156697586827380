@import 'styles/fonts.styles';
.participants-modal {
	hr {
		color: $line;
		background-color: $line;
		height: 1px;
		border: none;
		margin: 18px auto;
	}
	.close-button{
		visibility: hidden;
	}
	.delete-modal-content{
		text-align: left;
	}
	.actions-buttons {
		margin-top: 0px;
		justify-content: end;
		width: 100%;
		flex-direction: row-reverse;
		.expiry-back-button {
			color: $link;
			border: none;
			background: none;
			min-width: unset;
			margin-right: 10px;
			cursor: pointer;
		}
		.expiry-back-button:hover {
			box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
			border-radius: 5px;
		}
	}
}
.extend-expiry {
	.modal-content {
		width: 100%;
	}
	.row{
		display: flex;
		justify-content: space-between;
		.dropdown_select, .datetime-picker-wrapper {
			width: 48%;
		}
	}
	.extend-expiry-data{
		margin: 20px 0 0 0;
		.data-row {
			display: flex;
			margin: 10px 0;
			overflow-wrap: break-word;
			.title {
				width: 100px;
				font-weight: normal;
				color: #8C8CA1;
				font-size: 16px;
			}
			.value {
				margin-left: 10px;
				width: 80%;
			}
		}
	}
	.main-container{
		border: none;
		box-shadow: none;
		.container{
			padding: 0;
			width: 100%;
		}
	}
}
.increase-test-duration {
	.data-row-header {
		font-weight: bold;
		color: $grey;
		font-family: 'Work Sans', sans-serif;
	}
	.data-row-values:hover {
		background-color: rgba(0, 0, 0, 0.01);
	}
	.data-row {
		display: flex;
		justify-content: flex-start;
		margin: 5px 0;
		width: 100%;
		line-height: 48px;
		.row {
			line-height: 20px;
			width: 24%;
			margin-right: 1%;
			display: flex;
			align-content: center;
			justify-content: center;
			align-self: baseline;
			&.title{
				justify-content: flex-start;
				font-weight: normal;
			}
			.sub {
				font-size: 0.7em;
				margin: 5px 1px;
			}
			font-size: 0.9em;
		}
		.alooba-input{
			width: 60%;
			margin-top: unset;
		}
	}
	.data-row-footer{
		margin-top: 20px;
	}
	.actions-buttons .primary, .actions-buttons .sub-primary{
		margin-right: 8px;
		margin-left: 8px;
	}
	.actions-buttons .sub-primary.action-bt{
		cursor: default;
		background-color: #EEEEEE;
		border: none;
		color: #999999;
	}
}
