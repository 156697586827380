@import '../../styles/colors.scss';

.candidate-interview-selection {
  margin-top: 10px;
  background-color: $mainBackground;
  padding: 20px;

  .message {
    display: flex;
    margin: 10px 0px;
    .icon {
      margin-right: 10px;
    }
  }

  .candidate-details {
    display: flex;
    align-items: center;
    .icon {
      margin-right: 10px;
    }
  }

  .interview-selection {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: top;

    .dropdown_select {
      width: calc(100% - 220px);
      min-width: 200px;
      margin: 0px;
      div {
        margin: 0px;
      }
    }
    button {
      margin-top: 4px;
      height: 100%;
    }
  }

  .interview-details {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .stat {
      display: flex;
      align-items: center;
      min-width: 150px;
      .icon {
        margin-right: 10px;
      }
      .value {
        font-weight: bold;
      }
      .label {
        margin: 0px 0.3em;
      }
    }
  }
}

.interview-invite-fields {
  padding: 0px 20px;
  h3 {
    margin: 20px 0px 8px;
  }
  .alooba-input, .dropdown_select {
    margin-top: 5px;
  }
  .interview-schedule-fields {
    display: flex;
    justify-content: space-between;
    > div {
      width: 48%;
    }
  }

  .multiselect {
    .add_button {
      width: 168px;
      min-width: 168px;
    }
  }
 }