@import '../../styles/colors.scss';

@mixin flex-display {
  display: flex;
  align-items: center;
}
.assessment-page-actions {
  @include flex-display;
  width: max-content;
  position: absolute;
  right: 0;
  @media only screen and (max-width: 992px) {
    justify-content: space-between;
    flex-direction: row;
    right: 0;
  }
  @media only screen and (max-width: 768px) {
    top: 72px;
  }
  justify-content: flex-end;
  .flat-pill {
    margin-right: 12px;
    width: 150px;
  }
  .stat-icon {
    .with-tooltip {
      @include flex-display;
      justify-content: center;
    }
  }
}

.assessment-container {
  hr {
    margin: 8px 0 0 0 !important;
  }
}
