.comment-input {
    .buttons {
        display: flex;
        align-items: center;
        .sub-primary {
            margin-left: 10px;
            font-weight: bold;
            font-size: 16px;
            border: none;
            background-color: transparent;
            box-shadow: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}