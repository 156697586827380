@import '../../../styles/colors.scss';
@import '../../../styles/responsiveness.scss';

.internal-notes-container {

    hr {
        width: 100%;
    }
    .heading {

        margin-top: 30px;
        margin-bottom: 6px;
    }

    .comment-input-area {
        margin-bottom: 20px;

        .input-toggler {
            display: flex;
            align-items: center;
            button {
                margin-left: 50px;
            }
        }
    }


    .individual-note {
        display: flex;
        align-items: center;
        padding: 20px 0;

        &.error {
            .note-container {
                .name {
                    display: flex;
                    align-items: center;
                    #comment-error {
                        margin-right: 5px;
                    }
                }
            }
        }

        &:not(:last-child) {
            border-bottom: 1px $line solid;
        }
        .avatar {
            font-family: "Nunito Sans", sans-serif;
            font-weight: bold;
            margin-right: 30px;
        }
        .note-container {
            width: 100%;
            .note-heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .name {
                    color: $dark;
                    font-weight: bold;
                }
                .extra {
                    display: flex;
                    align-items: center;
                    .date {
                        color: $slate;
                        font-size: 12px;
                        margin-right: 12px;
                    }
                }
            }

            .comment {
                color: $dark;
            }

        }
    }
}