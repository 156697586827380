.legend {
    display: flex;
    justify-content: center;
    
    div {
        margin: 0 10px;
    }
    .correct::before {
        color: #03CEA4;
        content: '⬤ ';
    }
    .incorrect::before {
        color: #D62828;
        content: '⬤ ';
    }
}

.vertical_bar_main_hood {
    rect{
        stroke-width: 1;
        stroke: white;
        &.correct {
            fill: #03CEA4;
        }
        &.incorrect {
            fill: #D62828,
        }
        &.highlight {
            fill: #004385 !important;
        }
    }

    .hover-balloon {
        fill: #ECF1F4;
        filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 2px 1px);
    }
}

.question-insight-skeleton {
    display: flex;
    align-items: flex-end;
    grid-column-gap: 5px;
    &>span {
        display: block;
        flex: 10%;
    }

}