@import '../../../../styles/colors.scss';

.question-sidebar {
  height: 100%;
  min-width: 314px;
	@media only screen and (min-width: 801px) {
    max-width: 35%;
  }
  .line {
    flex-grow: 1;
    border-bottom: 1px solid $mainBackground;
    margin: 0 13px;
  }
  .spacer {
    flex-grow: 1;
  }
  .subject-collapse {
    color: $blue;
    padding: 10px 10px 10px 15px;
    cursor: pointer;
  }
  .title-section {
    padding: 20px 0;
    display: flex;
    align-items: center;
    .total-questions {
      margin-left: 5px;
      font-size: 16px;
      font-weight: 700;
      padding: 0.2rem 0.8rem;
      border-radius: 1rem;
      color: $dark;
      background: $mainBackground;
      margin-right: 20px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .subject-section-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 10px 20px 0;
    .category-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      .scored-percent {
        color: $blue;
        font-size: 14px;
        font-weight: bold;
        img {
          margin-top: 5px;
        }
      }
      .with-tooltip {
        margin-right: 3px;
        img {
          width: 16px;
          &.highlighted {
            filter: brightness(100%) !important;
          }
        }
      }
    }
  }
}
.questions-list {
  display: flex;
  flex-wrap: wrap;
  &.hidden {
    display: none;
  }
  .candidate-question-button {
    margin-right: 10px;
    margin-bottom: 5px;
  }
}
.candidate-question-button {
  width: 36px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 4px 6px;
  cursor: pointer;
  background-color: $mainBackground;
  color: $blue;
  &.selected {
    border: 1px solid $dark;
    box-shadow: 0px 4px 15px 0px #00000040;
  }
  &.correct {
    background-color: $success;
    color: $white;
  }
  &.wrong {
    background-color: $error;
    color: $white;
  }
  &.bad {
    border: 1px solid $error;
  }
  &.ok {
    border: 1px solid $orange;
  }
  &.good {
    border: 1px solid $success;
  }
  &.unanswered {
    background-color: $slate;
    color: $white;
  }
}