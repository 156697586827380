@import './colors.scss';

@mixin subtle-scroll {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: $light-cloud;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #E0E9EC;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $slate;
  }
}
