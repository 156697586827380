@import '../../styles/colors.scss';

.credit-usage {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
        display: block;
    }
    .danger {
        background-color: red;
    }
    .danger-text {
        color: red;
    }
    .details {
        flex: 1;
        .credits-used-section {
            font-size: 14px;
            color: #4a4a4a;
            margin-bottom: 8px;
            margin-top: 10px;
            font-weight: bold;
            display: flex;
            justify-content: space-between;

            .credits-used-title {
                font-weight: bold;
            }
        }

        .credit-number {
            font-weight: bold;
        }

        .progress-bar {
            width: 100%;
            height: 10px;
            background-color: #f0f0f0;
            border-radius: 15px;
            overflow: hidden;
        }
        .progress-fill {
            border-radius: 15px;
            display: block;
            height: 100%;
            background-color: $blue;
        }

          .remaining-credits {
            color: #4a4a4a;
            margin-top: 8px;
          }

          .additional-credits-available {
            color: #4a4a4a;
            margin-top: 4px;
          }
    }

    .action {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        @media (max-width: 768px) {
            justify-content: center;
        }
    }
}

.credit-usage-page {
    margin-top: 30px;
    h2 {
        font-size: 20px;
        margin-top: 24px;
        margin-bottom: 16px;
    }

    @media (max-width: 1023px) {
        margin-top: 70px;
    }

    span.positive-credit {
        content: "+";
        color: green;
        font-size: 1.5em;
        font-weight: 900;
    }
    span.negative-credit {
        content: "-";
        color: red;
        font-size: 1.5em;
        font-weight: 900;
    }
}