@import './src/styles/colors.scss';

div.alooba-input.alooba-switch {
  display: flex;
  label {
    font-size: 16px;
    line-height: 25px;
    margin-top: 10px;
  }
}

.title-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .badge {
    margin-left: 10px;
    padding: 2px 8px;
    border: 1px solid $pink;
    border-radius: 20px;
    color: $pink;
    font-weight: bold;
  }
}