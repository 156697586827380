.score-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #ECF1F4;
  box-sizing: border-box;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  height: 40px;
  color: #0E0E2C;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Nunito Sans', sans-serif;
  border-radius: 5px;
  padding: 0 8px;
  .score-part {
    padding: 3px 5px;
    line-height: 32px;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.score-filter{
      width: 25px;
      text-align: center;
      border-radius: 5px;
      background: white;
      input {
        outline: none;
        border: none;
        background: transparent;
        padding: 0;
        margin: 0;
        text-align: center;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
    }
  }
  .icon-direction{
    color: #004385;
    margin-right: 7px;
    margin-left: 4px;
    cursor: pointer;
    font-size: 13px;
  }
  .filter-type{
    color: #004385;
    font-weight: bolder;
    box-shadow: none;
    &.left {
      padding-right: 7px;
    }
    &.right {
      padding-left: 7px;
    }
  }
}