.pageHeaderContainer {
  height: 98px;
  top: 70px;
  position: fixed;
  z-index: 18;
  background-color: #fafafa;
  width: 100%;
  padding-top: 23px;
  @media only screen and (max-width: 992px) {
    width: calc(100% - 30px) !important;
  }
  &.hasBanner {
    top: 110px;
    .pageHeader {
      top: 110px;
    }
    @media only screen and (max-width: 1490px) {
      top: 150px;
      .pageHeader {
        top: 150px;
      }
    }
  }
  .pageHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: #fafafa;
    border-bottom: 1px solid #ecf1f4;
    height: 98px;
    top: 70px;
    position: fixed;
    z-index: 19;
    padding-top: 23px;
    @media only screen and (max-width: 992px) {
      width: calc(100% - 55px) !important;
    }
    .assessmentLink {
      height: 22px;
    }
    h3 {
      @media only screen and (max-width: 768px) {
        margin-right: 0;
      }
      color: #004385;
      font-family: Work Sans, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      white-space: nowrap;
      text-overflow: ellipsis;
      /* identical to box height */
      letter-spacing: -0.02em;
      text-align: left;
      overflow: hidden;
    }
    .breadcrumb {
      font-size: 22px;
      margin: 0 8px;
    }
    .candidateName {
      font-size: 22px;
      height: 24px;
      margin-top: -2px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
