
@import '../../../styles/colors.scss';
.color-picker {
  *[role=button] {
    border-radius: 5px;
  }
  .color {
    width: 30px;
    height: 30px;
    cursor: pointer;
    &:hover:not([disabled]) {
      transform: scale(1.0);
    }
  }
  .popover {
    button {
      position: relative;
      color: #4a4a68;
      float: right;
      top: -41px;
      right: 10px;
      background-color: $line;
      padding: 5px 10px;

      &.save {
        margin-left: 2px;
      }

      &.cancel {
        background-color: rgba($line, 0.5);
        margin-left: 2px;
      }
    }
    position: absolute;
    z-index: 2;
    transform: translate(-8px, 20px);
  }
}
