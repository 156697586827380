@import '../../../styles/responsiveness.scss';

.self-awareness-container {
    display: flex;

    .chart-container {
        flex: 45%;
    }

    .table-container {
        flex: 55%;
    }



    @include respond(laptop-strict) {
        flex-wrap: wrap;
    }
    @include respond(laptop) {
        flex-wrap: nowrap;
        .chart-container {
            flex: 60%;
        }

        .table-container {
            flex: 40%;
        }
    }
     @media only screen and (max-width: 1200px){
        flex-wrap: wrap;
        .chart-container, .table-container {
            flex: 100%;
        }

        .table-container {
            margin-top: -75px;
        }
    }
    @include respond(phone) {
        flex-wrap: wrap;
    }

}