.graphic-placeholder-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
        margin-bottom: 10px;
    }
}