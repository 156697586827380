@import 'styles/colors';

.groups-filter {
  min-width: 160px !important;
}

.filters-throbber,
.filters-throbber .fa-spin {
  color: $grey;
}

.filters-throbber-wrapper {
  display: none;
  padding-bottom: 15px;
  padding-left: 8px;
}

.filters-throbber-wrapper.visible {
  display: block;
}

.filters-skeleton {
  margin-left: 15px;
}