.personal-details {
  width: 100%;
  box-sizing: border-box;
  .form {
    .row {
      display: flex;
      justify-content: space-between;
      .alooba-input, .dropdown_select {
        width: calc(50% - 12px);
      }
    }
  }
  .buttons-container {
    display: flex;
    justify-content: center;
  }
  .main-container > .container > .row:first-of-type > .alooba-input {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .personal-details {
    .form {
      .row {
        flex-direction: column;
      }
      .alooba-input, .dropdown_select {
        width: 100%;
      }
    }
  }
}