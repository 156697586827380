.comparison-result {
    background-color: #ffffff;
    border-radius: 5px;
    border-bottom: 1px solid #dedfe1;
    .comparison-section-title {
        border-radius: 0;
        border: 1px solid #dedfe1;
        border-bottom: 0;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 35px;
        letter-spacing: -0.02em;
        color: #202020;
        padding: 14px;
        text-transform: uppercase;
    }
    .table-row {
        padding: 16px;
        border: 1px solid #dedfe1;
        border-bottom: 0;
        display: flex;
        align-items: center;
        .row-item {
            flex: 1;
            width: 33.3%;
            min-width: 33.3%;
        }
        .row-item:not(.comparison-basis) {
            text-align: center;
        }
        .candidate-name > a {
            color: #305083;
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            letter-spacing: -0.02em;
        }
        .score {
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            text-align: end;
            color: #404040;
            img {
                width: 20px;
                margin-left: 4px;
                padding-top: -4px;
                transform: translateY(4px);
            }
        }
    }
    .table-head {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-top: 1px solid #dedfe1;
        background-color: #eee;
    }
}
.skeleton {
    margin-top: 12px;
}
.select-candidates {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 22px;
    color: #202020;
}