@import '../../../../styles/colors.scss';
.coding-response-section {
  box-sizing: border-box;
  .candidate-response-wrapper {
    box-sizing: border-box;
    width: 100%;
    height: 220px;
    border-radius: 5px;
    border: 1px solid $mainBackground;
    word-break: break-word;
    &.good, &.correct {
      border: 2px solid $success;
    }
    &.wrong, &.bad {
      border: 2px solid $error;
    }
    &.ok {
      border: 2px solid $orange;
    }
    &.unanswered {
      border: 2px solid $disabled;
    }
    .ace_editor {
      border-radius: 5px;
    }
    .ace_gutter {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    .ace_scroller {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .ace_editor *, .ace_editor div, .ace_editor {
      font-family: monospace;
    }
  }
}
.free-response-uploaded-file {
  display: flex;
  align-items: center;
  word-break: break-word;
  margin-bottom: 5px;
}
.free-response-uploaded-file.with-icon {
  display: flex;
  align-items: center;
  word-break: break-word;
  margin-bottom: 5px;
  img {
    max-width: 100%;
    max-height: 400px;
  }
  .icon {
    margin-left: auto;
  }
  a, video, p {
    margin-left: 5px;
    margin-right: auto;
    max-width: 100%;
  }
}
.free-response-uploaded-file:not(.with-icon) {
  display: flex;
  align-items: center;
  word-break: break-word;
  margin-bottom: 5px;
  img {
    max-width: 100%;
    max-height: 400px;
    margin-right: auto;
    margin-left: auto;  
  }
  a, video {
    margin-right: auto;
    max-width: 100%;
    margin-left: auto;  
  }
}
.test-case {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  color: $dark;
  margin-bottom: 13px;
  cursor: pointer;
  min-height: 35px;
  .test-case-name {
    flex-grow: 1;
    margin: 0 13px;
    color: $blue;
    &:hover {
      text-decoration: underline;
    }
  }
  .right-icon {
    margin-top: 5px;
    margin-right: 13px;
  }
  &.correct {
    border: 2px solid $success;
  }
  &.wrong {
    border: 2px solid $error;
  }
  &.not-evaluated {
    border: 2px solid $disabled;
  }
}
.test-case-output {
  display: grid;
  border: 1px solid $line;
  border-radius: 5px;
  background-color: $hover;
  margin-bottom: 13px;
  padding: 10px;
  .inner-title {
    color: $dark;
    font-weight: 700;
    font-size: 16px;
    margin-top: 20px;
    margin-left: 10px;
    margin-bottom: 5px;
  }
  .inner-title:first-child {
    margin-top: 0;
  }
  .test-case-output-wrapper {
    max-height: 150px;
    width: calc(100% - 20px);
    overflow: auto;
    border-radius: 5px;
    padding: 8px 10px;
    background: #eee;
    code {
      white-space: pre;
      .error {
        color: $error
      }
    }
  }
  &.hidden {
    display: none;
  }
}
.sql-output-table {
  box-sizing: border-box;
  max-height: 224px;
  overflow-y: scroll;
  color: $dark;
  border: 2px solid $line;
  border-radius: 5px;
  font-family: "Nunito sans", sans-serif;
  max-width: 100%;
  &.good, &.correct {
    border: 2px solid $success;
  }
  &.wrong, &.bad {
    border: 2px solid $error;
  }
  &.ok {
    border: 2px solid $orange;
  }
  * {
    color: $dark;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    thead {
      position: sticky;
      top: -1px;
    }
    tfoot {
      position: sticky;
      bottom: -1px;
      border-top: 2px solid $line;
    }
    td, th {
      border: 1px solid $line;
      text-align: left;
      padding: 0 8px;
      height: 30px;
      line-height: 30px;
    }
    tr {
      background-color: white;
    }
    tr:nth-child(even) {
      background-color: $hover;
    }
    .sql-table-heading {
      background-color: $line;
    }
    .sql-table-footer {
      background-color: white;
    }
  }
  .sql-error-message {
    padding: 6px;
  }
}

.response-section-wrapper {
  .transcript-header {
    display: flex;
    .transcript-tooltip {
      margin-left: 4px;
    }
  }
  .candidate-response-unavailable {
    font-style: italic;
    padding: 4px;
    border-radius: 5px;
    margin-bottom: 12px;
  }
  .alooba-input {
    margin-top: 0px;
    textarea {
      resize: vertical;
    }
  }
  @media only screen and (min-width: 1800px) {
    display: flex;
    flex-direction: row;
    .indicators, .model-response-section {
      display: flex;
      flex-direction: column;
      width: calc(50% - 15px);
      max-width: calc(50% - 15px); 
    }
    .candidate-response-section {
      width: 50%;
      max-width: 50%;
      margin-left: 15px;
    }
    .test-cases-section {
      width: 100%;
      min-width: 100%;
    }
  }
}
.upgrade-your-account {
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding: 12px;
  border-radius: 5px;
  border: 2px solid $orange;
  .warning-icon {
    margin-right: 6px;
    padding-top: -14px;
  }
}