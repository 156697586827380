.header {
  display: flex;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 20;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 70px;
  margin-bottom: 10px;
  background-color: #fff;
  &:not(.flat) {
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  }
  &.flat {
    border-bottom: 1px solid #DEDFE1
  }
  &.has-banner {
    top: 40px;
  }
  @media only screen and (max-width: 1490px) {
    &.has-banner {
      top: 80px;
    }
  }
}

menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0;
  padding: 0;
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logo {
  width: 145px;
  height: 32px;
  background: url(../../assets/dark-logo.svg) 0 50% no-repeat;
  background-size: contain;
}

.icons {
  display: flex;
  height: 100%;
  align-items: center;

  .icon {
    padding: 8px;
    cursor: pointer;
  }
}

.avatar-dropdown {
  position: relative;
  cursor: pointer;
}

.avatar-dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  min-width: 200px;
  list-style-type: none;
  border: 1px solid #ecf1f4;
  border-radius: 5px;
  background-color: #fff;
  z-index: 999;

  a {
    display: block;
    padding: 16px;
    text-decoration: none;
    color: #103662;

    &:hover {
      text-decoration: none !important;
      background-color: #ecf1f4;
    }
  }
}

.show {
  display: block;
}

.mobile-container {
  svg {
    width: 25px;
    height: 25px;
  }
  padding-left: 8px;
  display: none;
  @media only screen and (max-width: 992px) {
    display: inline-block;
  }
}
