@import './src/styles/colors';

.reject-modal-wrapper.modal-container {
  width: 45%;
  min-width: 380px;
}
.reject-candidate-modal{
  h2 {
    margin-bottom: 22px;
  }
  .reasons {
    display: flex;
    grid-gap: 10px;
    margin-bottom: 12px;
    &>* {
      flex: 1;
    }
    .MuiInput-fullWidth {
      max-width: 100%;
    }
    .alooba-input {
      margin-top: 5px;
    }
    input {
      padding: 8px;
      width: 100%;
    }
  }
  .note-input {
    .alooba-input {
      margin-top: 6px;
    }
  }
  .candidate-notification-fields {
    margin-top: 35px;
    padding: 15px;
    background-color: $light-border;

    #notification-description {
      margin-top: 6px;
    }
    .alooba-switch {
      .MuiSwitch-root {
        margin-left: 0px;
      }
    }
  }
}