#groups {
  hr {
    border: 1px solid #ECF1F4;
    margin: 16px 0;
  }
  .intro {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .desc {
      width: 100%;
    }
  }
  .member-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .member-form-content {
      display: flex;
      width: calc(100% - 70px);
      margin-bottom: 16px;
      border-radius: 5px;
      .alooba-input {
        margin-top: unset;
        width: 30%;
        max-width: 300px;
      }

      .members {
        width: 70%;
        margin-left: 24px;
        .alooba-input.multiselect {
          width: 100%;
          max-width: none;
        }
      }
    }
    .delete {
      svg {
        color: #c0bdbd;
      }
    }
  }
}