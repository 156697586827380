@import '../../../../styles/colors.scss';
.test-question-details {
  font-family: 'Nunito Sans', sans-serif;
  width: 100%;
  overflow: hidden;
  * {
    font-family: 'Nunito Sans', sans-serif;
  }
  h4 {
    margin: 15px 0 10px 0;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0;
  }
  .question-details-heading {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px 0;
    .question-specs {
      display: flex;
      align-items: center;
    }
    .question-difficulty,
    .question-time {
      display: flex;
      align-items: center;
      margin: 8px 10px 0 0;
      img {
        margin: 0 10px 0 0;
      }
    }
    .question-time {
      margin-right: 20px;
    }
    .subjects {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;
      .subject {
        margin-top: 5px;
        padding: 0.2rem 0.8rem;
        border-radius: 1rem;
        border-style: solid;
        border-color: $mainBackground;
        background: $mainBackground;
        margin-right: 20px;
        white-space: nowrap;
        overflow: hidden;
        &.organisation {
          background-color: white;
        }
      }
    }
  }
  .question-marks {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 9px 10px 0 0;
  }
  .question-background {
    margin-top: 20px;
    background-color: $blue;
    padding: 17px 16px 11px 20px;
    border-left: 4px solid $orange;
    p {
      color: white;
      margin-bottom: 10px;
      img {
        max-width: 100%;
        max-height: 500px;
        min-height: 120px;
      }
    }
    .math {
      color: white;
    }
    h4 {
      margin-bottom: 10px;
    }
    .background-title {
      font-size: 16px;
      font-weight: 700;
      color: white;
    }
    .question-image {
      img {
        max-width: 100%;
        max-height: 500px;
        min-height: 120px;
      }
    }
    &.question-background-collapsable {
      &.collapsed {
        max-height: 120px;
        overflow-y: hidden;
        overflow-x: hidden;
      }
    }
    &.gradient-overlay {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(transparent),
          to($blue)
        );
      }
    }
    .data-sets {
      .background-title {
        margin: 16px 0 10px 0;
      }
      .data-set-row {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        .data-set-card {
          word-break: break-all;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-width: 120px;
          max-width: 120px;
          height: 73px;
          background-color: white;
          border-radius: 10px;
          margin-right: 16px;
          cursor: pointer;
          img {
            margin-bottom: 4px;
          }
          p {
            color: $dark;
            word-break: break-all;
            margin-top: 4px;
            padding: 2px;
            line-height: 16px;
            text-align: center;
            max-width: 100%;
          }
        }
      }
    }
    .show-hide {
      color: white;
      position: absolute;
      background-color: #004385;
      width: 100%;
      bottom: 0;
      z-index: 2;
      &.relative {
        position: relative;
      }
      margin-bottom: 6px;
      .show-hide-message {
        cursor: pointer;
        display: flex;
        align-items: center;
        .icon {
          margin-left: 5px;
        }
      }
      hr {
        margin-top: 15px;
        margin-bottom: 20px;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a,
    li {
      color: white;
    }
    h1 {
      font-size: 28px;
    }
    pre {
      overflow-x: auto;
    }
    pre > code {
      color: $dark;
    }
    code:not([class^='language-']) {
      color: white;
      background-color: rgba(238, 238, 238, 0.205);
    }
  }
  code:not([class^='language-']) {
    background-color: #eee;
    font-family: monospace;
    padding: 3px;
    border-radius: 3px;
  }
  table {
    background: white;
    color: $grey;
    border: 1px solid $light-border;
    max-width: 100%;
    overflow-x: auto;
    font-size: 14px;
    display: inline-block;
    border-collapse: collapse;
    border-radius: 1px;
    th,
    td {
      padding: 5px 15px 5px 10px;
    }
    th {
      background: $light-cloud;
      text-align: left;
      font-weight: bold;
      border-bottom: 1px solid $light-border;
    }
    tr:nth-child(even) {
      background: $light-cloud;
      border-top: 1px solid $light-border;
      border-bottom: 1px solid $light-border;
    }
  }
}
