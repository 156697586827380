@mixin flex-display {
  display: flex;
  align-items: center;
}
.heading {
  @include flex-display;
  margin-bottom: 10px;
	h3 {
	  color: #004385;
	  font-family: Work Sans, sans-serif;
	  font-style: normal;
	  font-weight: 700;
	  font-size: 22px;
	  line-height: 26px;
	  /* identical to box height */
	  letter-spacing: -0.02em;
	  text-align: left;
	}
}