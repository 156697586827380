@import '../../styles/colors.scss';


button {
    background: none;
    box-shadow: none;
    &:disabled {
        opacity: 0.5;
        .question-number {
            &:hover {
                text-decoration: none;
            }
        }
    }
}
.question-button {
    width: 36px;
    height: 48px;
    background-color: $mainBackground;
    border-radius: 3px;
    text-align: center;
    margin: 10px 10px;

    .question-number {
        padding: 4px 0;
        font-weight: bold;
        clear: both;
        color: $blue;
        &:hover {
            text-decoration: underline;
        }
    }

    .badge {
        background-color: $grey;
        color: white;
        border-radius: 0 0 0.2rem 0.2rem;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        margin-top: 5px;
    }
}
