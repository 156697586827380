.custom-date-picker {
  z-index: 2;
  .react-datepicker-popper[data-placement^=bottom] {
    padding-top: unset;
  }
  .react-datepicker-popper[data-placement^=top]{
    padding-bottom: unset;
  }
  .datepicker-input{
    display: none;
  }
  .react-datepicker {
    background: #FFFFFF;
    border: 1px solid #ECF1F4;
    box-sizing: border-box;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-family: Nunito Sans, serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    justify-content: center;
    .react-datepicker__header{
      background-color: white;
      border-bottom: none;
    }
    .react-datepicker__navigation-icon {
      top: 10px;
    }
    .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #0E0E2C;
      margin-bottom: 15px;
      text-align: left;
      font-weight: normal;
      margin-left: 10px;
    }
    .react-datepicker__navigation--previous{
      right: 40px;
      left: unset;
    }
    .react-datepicker__triangle{
      display: none;
    }
    .react-datepicker__navigation{
      color: #4A4A68;
      border-color: #4A4A68 !important;
    }
    .react-datepicker__navigation:hover *::before, .react-datepicker__navigation *::before{
      color: #4A4A68;
      border-color: #4A4A68 !important;
      border-width: 2px 2px 0 0;
    }
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #8C8CA1;
    }
    .react-datepicker__day{
      margin: 0;
      padding: 7px;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: -0.02em;
      margin-top: 10px;
      color: #004385;
      border-radius: 18px;
      &.react-datepicker__day--range-start{
        border-bottom-left-radius: 18px;
        border-top-left-radius: 18px;
      }
      &.react-datepicker__day--range-end{
        border-bottom-right-radius: 18px;
        border-top-right-radius: 18px;
      }
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      color: #004385;
      border-radius: 0;
      background: #ECF1F4;
    }
    .custom-date-picker .react-datepicker .react-datepicker__day--in-selecting-range{
      border-radius: 18px;
    }
  }
  .react-datepicker__tab-loop {
    z-index: 2;
  }
  .actions-buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    width: 100%;
    border-top: 2px solid #DEDFE1;
    padding-top: 20px;
    button {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.02em;
      &:hover:not([disabled]) {
        transform: none;
      }
    }
    .sub-primary{
      background-color: white;
      color: #004385;
    }
  }
}