.custom-select-menu {
  .MuiPaper-root {
    border: 1px solid #ecf1f4;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    margin-top: 6px;
    margin-left: -1px;
    width: 250px;
  }
}
.render-select-value {
  display: flex;
  .render-select-value-text {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
