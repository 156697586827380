@import '../../../../styles/colors.scss';
.test-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid $line;
  @media only screen and (max-width: 800px){
    flex-direction: column;
    align-items: flex-start;
  }
  .spacer {
    flex-grow: 1;
  }
  .total-test-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    * {
      margin-left: 10px;
    }
    .retake-icon, .add-time-icon {
      cursor: pointer;
      margin-left: 0;
      img {
        margin-top: 3px;
      }
    }
  }
  .test-duration-text {
    color: $slate;
  }
  .divider {
    color: $mainBackground;
  }
}
.score-circle img {
  margin-top: 5px;
}