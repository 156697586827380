.trial-banner {
  height: 42px;
  position: fixed;
  z-index: 22;
  background-color: #004385;
  display: flex;
  width: 100%;
  .message {
    color: white;
    flex: 1;
    text-align: center;
    margin: 5px auto;
  }
  .buttons {
    position: absolute;
    top: 0;
    right: 5px;
    display: flex;
    button {
      font-weight: bold;
      margin: 2px 4px;
    }
  }
  &.urgent {
    background-color: #F68C3E;
  }

  @media only screen and (max-width: 1490px) {
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .buttons {
      position: unset;
    }
  }
  @media only screen and (max-width: 680px) {
    height: 95px;
  }
}
