.no-candidates-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    .card {
        box-sizing: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 30px;
        text-align: center;
        box-shadow: 0px 2px 1px 0px #0000000D;

        button.primary {
            margin-top: 20px;
        }

        .benchmark {
            font-weight: bold;
            color: #0E0E2C;
            text-align: left;
        }
    }

    .horizontal-flex {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pinch-horizontal {
        margin: 0px 20px;
    }

    .pinch-horizontal-fixed-max {
        max-width: 600px;
        width: 100%;
    }

    .vertical-gap {
        margin-bottom: 30px;
    }

    .small-vertical-gap {
        margin-bottom: 10px;
    }

    img {
        height: 100%;
        max-width: 100%;
    }

    .caption {
        max-width: 80%;
        min-height: 44px;
    }
}