.warningBar {
  display: flex;
  border: 1px solid #f68c3e;
  border-radius: 6px;
  background: #faf3ee;
  position: relative;
  margin-bottom: 8px;
  .closeBar {
    background: #f68c3e;
  }
  .textContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    p {
      font-size: 16px;
    }
  }
}
