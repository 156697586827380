@import '../../styles/colors.scss';
@import '../../styles/spacing.styles.scss';
@import '../../styles/responsiveness.scss';
@import 'react-image-lightbox/style.css';

.compare-candidates-wrapper {
    margin-top: 30px;
    background-color: #FAFAFA;
}

.select-candidate-boxes {

    margin: 10px 0px 30px 0;
    display: flex;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
    .select-candidate-box:not(:first-child) {
        @media screen and (max-width: 768px) {
            flex-direction: column;
            margin-top: 18px;
        }
        margin-right: 0;
    }
    .select-candidate-box {
        background-color: #ffffff;
        flex: 1;
        width: 100%;
        margin-right: 12px;
        flex-basis: 33%;
        border-radius: 5px;
        min-width: 200px;
        h3 {
            margin-bottom: 6px;
        }
        .select-box-content {
            border-radius: 5px;
            border: 1px solid #dedfe1;
            padding: 18px 20px;
            min-height: 63px;
        }
        @include respond(phone) {
            flex-basis: 100%;
        }
    }
}
.select-candidate-boxes:first-child {
    padding-left: 0;
}

.select__control:hover, .select__control-menu-is-open:hover,
.select__control, .select__control-menu-is-open {
    background: #FFFFFF;
    border: 1px solid #ECF1F4;
    box-sizing: border-box;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    outline: none;
}

.select__indicator-separator {
    display: none;
}

.select__menu {
    .select__option--is-selected {
        color: #03CEA4;
        background: inherit;
    }
}

.select-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;

    button {
        height: 38px;
    }

    .react-select {
        margin-top: 10px;
        flex-grow: 1;
        flex-basis: 100px;
    }
}