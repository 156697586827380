@import '../../styles/colors.scss';

.template-list {
  display: flex;
  flex-direction: column;
  h2 {
    margin-bottom: 10px;
  }
  .template-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    text-decoration: none;
    margin-top: 10px;
    &:not(:last-child) {
      border-bottom: 1px solid $line;
    }
    th {
      text-align: start;
    }
  }
}

.organization-templates {
  h2 {
    color: $dark;
  }
  h3 {
    color: $dark;
    margin-bottom: 20px;
  }
  button.primary {
    margin-top: 20px;
  }
  .templates-subtext {
    margin: 10px 0 20px 0;
  }
  .organization-template-editor {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .rdw-editor-wrapper {
      width: 100%;
    }
    .rdw-editor-main {
      background-color: white;
      border: 1px solid $line;
      padding: 0px 20px;
      min-height: 200px;
    }
    .rdw-option-active {
      box-shadow: none;
      border: 1px solid $line;
      background-color: $hover;
    }
    .rdw-option-wrapper:hover {
      box-shadow: none;
      background-color: $hover;
    }
    .rdw-link-modal-btn {
      color: $dark;
      margin-bottom: 5px;
      &:hover, &:active {
        box-shadow: none;
        border: 1px solid $line;
      }
    }
    .variables {
      width: 220px;
      margin-left: 20px;
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-top: 20px;
      }
      .variables-list {
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 768px) {
          flex-direction: row;
        }
        button {
          margin-bottom: 10px;
          @media screen and (max-width: 768px) {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
