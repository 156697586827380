.signup-flow, .subscription-page {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .wrapper {
      margin-left: 16px;
      margin-right: 16px;
      @media screen and (max-width: 800px){
        margin-left: 0;
        margin-right: 0;
      }
    }
    .main-container {
      padding: 30px;
      max-width: 1150px;
      margin: 0 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
      h1 {
        font-size: 38px;
        line-height: 56px;
        margin-bottom: 16px;
        padding: 0 20px;
        text-align: center;
      }
      h3.plan-name {
        text-align: center;
      }
      h3 {
        width: 100%;
        text-align: left;
        margin-top: 20px;
      }
      p.welcome {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
      }
      .errorMsg {
        padding: 0;
      }
    }
    .pricing-table {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      margin-top: 30px;
      .plan:first-child {
        margin-left: 0;
        max-width: fit-content;
      }
      .plan:last-child {
        margin-right: 0;
      }
    }
    .schedule-call-section {
      width: 100%;
      margin-top: 0;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      column-gap: 20px;
      @media screen and (max-width: 800px){
        flex-direction: column;
      }
      >p {
        text-align: left;
      }
      button {
        min-width: 130px;
        margin-top: 0;
      }
    }
    .promo-section {
      margin-top: 30px;
      .promo-form {
        display: flex;
        align-items: center;
        button {
          margin-left: 20px;
          margin-bottom: 6px;
        }
        .alooba-input {
          margin-top: 10px;
          width: 300px;
        }
      }
    }
    .welcome>a {
      font-weight: 800;
    }
    .form {
      width: 100%;
    }
    .form>.first-form-row {
      display: flex;
      align-items: baseline;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      flex-wrap: wrap;
      column-gap: 16px;
      width: 100%;
      .form-item {
        width: calc(33% - 16px);
        @media screen and (max-width: 800px){
          width: 100%;
        }
        .alooba-input {
          width: 100%;
        }
        .input-group, .react-tel-input, .tel>div.react-tel-input {
          width: 100%;
        }
        .flag-dropdown {
          width: 45px;
        }
        .selected-flag {
          width: 100%;
        }
        .tel {
          margin-top: 15px;
        }
      }
    }
    .second-form-row {
      display: flex;
      align-items: baseline;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      flex-wrap: wrap;
      column-gap: 16px;
      width: 100%;
      .form-item {
        width: calc(50% - 16px);
        @media screen and (max-width: 800px){
          width: 100%;
        }
        .alooba-input {
          width: 100%;
        }
      }
    }
    button {
      margin-top: 20px;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
    }
    button.disabled {
      background-color: #ffcc99;
    }
    .alooba-input {
      max-width: 800px;
      margin-bottom: 20px;
      width: 100%;
    }
    .row {
      width: 100%;
    }
    h2 {
      text-align: center;
    }
  }

  .request-trial-modal {
    h3 {
      text-align: center;
      margin-bottom: 24px;
    }
    p {
      margin-bottom: 12px;
    }
  }

  .subscription-inactive-wrapper {
    padding: 30px;
    h1 {
      text-align: center;
      font-size: 38px;
    }
    p {
      margin-top: 20px;
      text-align: center;
      font-size: 16px;
    }
  }