.text-light{
  font-weight: normal;
}
.progress-bar-container{
  .progress-bar-label{
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .bold{
    font-weight: bold;
  }
}
.legend-container {
  .legend-color{
    display: block;
    height: 12px;
    width: 16px;
    border-radius: 3px;
    margin-right: 10px;
  }
}
.red-text {
  color: #d62828;
}
.orange-text {
  color: #f68c3e;
}