.upsell-lite-subscription.main-container {
	border: 1px solid #004385;
	padding: 48px;
	.benefits {
		text-align: left;
		h4 {
			font-size: 18px;
			line-height: 25px;
		}
		p {
			font-size: 16px;
			line-height: 25px;
			a {
				font-size: 16px;
			}
		}
	}
}
