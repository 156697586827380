@import 'styles/fonts.styles';
.invite-participant-modal {
	hr {
		color: $line;
		background-color: $line;
		height: 1px;
		border: none;
		margin: 18px auto;
	}
	.close-button{
		visibility: hidden;
	}
	.delete-modal-content{
		text-align: left;
	}
	.actions-buttons {
		margin-top: 0px;
		justify-content: end;
		width: 100%;
		flex-direction: row-reverse;
		.expiry-back-button {
			color: $link;
			border: none;
			background: none;
			min-width: unset;
			margin-right: 10px;
			cursor: pointer;
		}
		.expiry-back-button:hover {
			box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
			border-radius: 5px;
		}
	}
}
.invite-participant-modal {
  .modal-content {
    .select-invite-type {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &.small {
        justify-content: space-evenly;
      }
      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        background-color: white;
        padding: 20px;
        max-width: 300px;
        h2 {
          margin-bottom: 15px;
        }
        .invite-candidate-text {
          margin-bottom: 10px;
          font-size: 16px;
        }
        button {
          font-size: 16px;
        }
      }
    }
    .invite-candidate-success {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 16px;
      p {
        margin: 15px 0;
      }
    }
    .invite-candidate {
      .invite-yourself-cta {
        margin: 10px 0;
      }

      .datetime-section .alooba-input {
        margin-top: 0px;
      }
    }
    
    .field-row {
      display: flex;
      align-items: center;
      .alooba-input {
        width: calc(50% - 10px);
        &:last-child {
          margin-left: 20px;
        }
      }
    }
    
    .datetime-section-title {
      font-size: 16px;
      margin: 20px 0 10px 0;
    }
    .datetime-section {
      display: flex;
      align-items: center;
      &.disabled {
        pointer-events: none;
        background: none;
        box-shadow: none;
      }
      .dropdown_select {
        width: calc(50% - 10px);
      }
      .datetime-picker-wrapper {
        width: calc(50% - 10px);
        margin: 20px 0 0 20px;
      }
    }
    
    .timeline {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
    
    .timestamp {
      margin-bottom: 20px;
      padding: 0px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 100;
      .time {
        font-size: 12px;
      }
      &:after {
          content: '';
          width: calc(100% + 40px);
          height: 2px;
          background-color: #D6DCE0;
          margin: 20px 0 -20px;
      }
      &.first:after {
        width: calc(50% + 20px);
        margin-left: calc(50% + 20px);
      }
      &.last:after {
        width: calc(50% + 20px);
        margin-right: calc(50% + 20px);
      }
    }
    
    .status {
      padding: 15px 20px;
      display: flex;
      justify-content: center;
      position: relative;
      white-space: nowrap;
      h4 {
        font-weight: 600;
      }
      &:before {
        content: '';
        width: 25px;
        height: 25px;
        background-color: white;
        border-radius: 25px;
        border: 1px solid #ddd;
        position: absolute;
        top: -15px;
      }
    }
    
    .list-item {
      &:before {
        display: none !important;
      }
    }
    
    .list-item.complete {
      .timestamp:after{
        background-color: $success;
      }
      .status {
        color: $success;
        &:before {
          background-color: $success;
          border: 1px solid $success;
        }
        &:after {
          background-color: $success;
        }
        h4 {
          color: $success;
        }
      }
    }
  }
  .hidden {
    display: none !important;
  }
}

@media only screen and (min-width: 2060px) {
  .invite-participant-modal {
    .modal-content {
      .select-invite-type {
        justify-content: center;
        .card {
          margin: 0 20px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1767px) {
  .invite-participant-modal {
    .modal-content {
      .select-invite-type {
        .card {
          max-width: 240px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  .invite-participant-modal {
    .modal-content {
      .select-invite-type {
        flex-direction: column;
        .card {
          min-width: 80%;
          max-width: 80%;
          margin: 20px auto;
        }
      }
    }
  }
}

@mixin timelineStyles {
  .timeline {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .list-item {
    display: flex;
    width: inherit;
  }
  .timestamp {
    width: 170px;
    margin: 20px 0 0 0 !important;
    &:after {
      display: none;
    }
  }
  .status {
    padding-left: 40px;
    &:before, &.first:before, &.last:before {
      left: 0;
      right: unset;
      top: 30%;
    }
    &:after {
      content: '';
      position: absolute;
      top: 42px;
      left: 12.5px;
      height: 28px;
      width: 2px;
      background-color: #D6DCE0; 
    }
  }
  .status.active {
    &:after {
      background-color: $success;
    }
  }
  .list-item.complete {
    .status {
      &:after {
        background-image: linear-gradient(to bottom,$success 0%, $success 50%, #D6DCE0 50%, #D6DCE0 100%);
      }
    }
  }
  .status.last {
    &:after {
      display: none;
    }
  }
}

.invite-participant-modal {
  .modal-content {
    .invite-candidate-success.vertical {
      @include timelineStyles;
    }
  }
}

@media only screen and (max-width: 1264px) {
  .invite-participant-modal {
    .modal-content {
      @include timelineStyles;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .modal-wrapper {
    width: 70%;
  }
}

@media only screen and (max-width: 600px) {
  .invite-participant-modal .modal-content {
    .timestamp {
      max-width: 100px;
    }
  }
}