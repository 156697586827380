.resultsoverview {
  position: relative;
  h2 {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 38px;
    letter-spacing: -0.02em;
    color: #004385;
  }
  h4 {
    margin-bottom: 10px;
  }
  h3 {
    margin-bottom: 15px;
  }
  .main-container {
    height: 100%;
    box-sizing: border-box;
    padding: 30px;
    .funnel_main_hood {
      height: initial;
    }
  }
  .spacing-bottom {
    margin-bottom: 20px;
  }
  .carousel {
    button {
      position: absolute;
      margin: -15px 0 0 0;
      z-index: 18;
    }
    .button-right {
      right: -20px;
    }
    .button-left {
      left: -20px;
    }
  }
}
.overview-carousel {
  padding: 30px 0 15px 0 !important;
}
.dv_label {
  color: black;
}
@media only screen and (max-width: 500px) {
  .dv_labels {
    flex-direction: column;
  }
  .dv_wrapper {
    width: 100% !important;
  }
}
