@import '../../styles/colors.scss';
@import '../../styles/responsiveness';

.top-results {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
  .top-results-card {
    min-width: 240px;
    margin: 0 10px;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    h3 {
      color: $blue !important;
    }
    .candidate-reference {
      position: relative;
      color: $disabled;
      font-size: 12px;
      .candidate-flags {
        position: absolute;
        top: 0;
        left: calc(50% + 53px);
        flex-direction: row;
        * {
          margin: 0 3px;
        }
      }
    }

    .candidate-score {
      color: $blue !important;
      font-weight: bold;
      font-size: 48px;
    }

    a {
      text-decoration: none;
    }
    button {
      margin: 24px auto 16px auto;
      padding: 8px;
      width: 80%;
    }

    .muted {
      color: $disabled;
      font-size: 0.8rem;
    }
  }
}

.participants-container {
  .dynamic-paper-container {
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    border-bottom: none;
    a {
      font-size: 14px;
      color: $dark;
      text-decoration: none !important;
    }
    .text-muted {
      color: $disabled;
    }
    .primary-color {
      color: $blue;
      font-weight: bold;
    }
    .success {
      color: $success;
    }
    table.dynamic-table {
      tbody {
        tr.dynamic-table-row {
          td.Name,
          td.Status {
            color: $blue;
          }
        }
        tr.dynamic-table-row:hover {
          color: $orange;
          td {
            color: $orange;
            &:hover:not(.Status):not(.Total) {
              z-index: 3;
            }
          }
        }
      }
      td {
        min-width: max-content;
      }
      th {
        z-index: 1;
      }
      th.sticky {
        z-index: 2;
      }
      td.sticky-left {
        z-index: 2;
      }
      td.sticky-left:nth-child(1),
      th.sticky-left:nth-child(1) {
        width: 120px;
      }
      td.sticky-right:nth-last-child(1),
      th.sticky-right:nth-last-child(1) {
        width: 40px;
      }
      td.sticky-left:hover,
      th.sticky-left:hover,
      td.sticky-right:hover,
      th.sticky-right:hover {
        z-index: 2;
      }
      td.sticky-left.end,
      th.sticky-left.end {
        border-right: 3.3px solid #ecf1f4;
      }
      td.sticky-right.start,
      th.sticky-right.start {
        border-left: 3.3px solid #ecf1f4;
      }
    }
  }

  .candidate-name-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 150px;
  }
  .reference-cell-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .candidate-flags {
    .with-tooltip {
      margin-left: 10px !important;
    }
    display: flex;
    img {
      height: 18px;
      margin-left: 10px;
    }
  }
}

@include respond(desktop) {
  $n: 50;
  @for $i from 1 through $n {
    td.sticky-left:nth-child(#{$i}),
    th.sticky-left:nth-child(#{$i}) {
      @if ($i == 1) {
        left: 0;
      } @else {
        left: ($i * 125) - 126px;
      }
    }
  }
  td.sticky-right,
  th.sticky-right {
    right: 0;
  }
}

@media screen and (max-width: 1500px) {
  .top-results .top-results-card {
    min-width: 180px;
    .candidate-reference {
      .candidate-flags {
        left: calc(50% + 28px);
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .top-results .top-results-card {
    min-width: 300px;
  }
}
.normal-mouse {
  cursor: unset;
}
.bold {
  font-weight: bold !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.total.score a {
  color: #0e0e2c;
}
.skill-score {
  margin-left: -22px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-row {
  color: black;
  padding: 4px 15px;
  cursor: pointer;
}

.filter-row:hover {
  background-color: #F5F5F5;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  transition: transform .35s ease-in-out;
  // top: 35px;
  background-color: white;
  right: -10px;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  min-width: 260px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 40;
  font-family: "Nunito Sans", sans-serif;

  .selected {
    font-weight: 600;
  }
}

.columns-dropdown-toggle:hover {
  cursor: pointer;
  filter: hue-rotate(170deg) brightness(215%) !important;
}

.dropdown-transition-enter {
  transform: scale(0);
}
.dropdown-transition-enter-active {
  transform: scale(1);
  transition: transform 100ms;
}
.dropdown-transition-exit {
  transform: scale(1);
}
.dropdown-transition-exit-active {
  transform: scale(0);
  transition: transform 100ms;
}