@import 'styles/fonts.styles';
@import '../../styles/responsiveness';

@mixin flex-display {
  display: flex;
  align-items: center;
}
@mixin block-display {
  display: inline-block;
}

#participants.container {
  display: flex;
  align-self: flex-start;
  @media only screen and (max-width: 1200px) {
    @include block-display;
  }
}
.add-participants-chevron {
  display: none;
  @media only screen and (max-width: 1200px) {
    justify-content: space-between;
    width: 100%;
    margin: 16px auto;
    display: flex;
  }
}
.participants-top-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.kanban-switcher {
  display: flex;
  align-items: center;
}
.participants-container {
  display: flex;
  box-sizing: border-box;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 1px solid #dedfe1;
  width: 100%;
  justify-content: center;
  background-color: unset;
  @media only screen and (max-width: 1200px) {
    @include block-display;
  }
  .dynamic-table-container {
    background-color: white;
    padding: 16px;
    min-height: 550px;
    .reference {
      display: flex;
      justify-content: space-between;
      .candidate-flags {
        .with-tooltip {
          margin-left: 10px !important;
        }
        display: flex;
        img {
          height: 20px;
          margin-left: 10px;
        }
      }
    }
  }
  .dynanic-table-container-no-min-height {
    min-height: unset;
  }
  .dynamic-paper-container.collapsed {
    max-width: calc(100vw - 40px);
  }
  .dynamic-paper-container.full {
    max-width: calc(100vw - 330px);
    @media only screen and (max-width: 992px) {
      max-width: calc(100vw - 65px);
    }
  }
  .dynamic-paper-container {
    overflow-y: visible;
    overflow-x: scroll;
    display: flex;
    border-bottom: none;
    table.dynamic-table {
      tbody {
        tr.dynamic-table-row {
          td.Name,
          td.Status {
            color: $blue;
            min-width: 170px;
          }
          td.Name .primary-color {
            max-width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        tr.dynamic-table-row:hover {
          color: $orange;
          td {
            color: $orange;
          }
          .tooltiptext {
            z-index: 3;
          }
        }
      }
    }
  }
}
.add-participants {
  .add-participants-bar {
    @include flex-display;
    @media only screen and (max-width: 1200px) {
      position: relative;
      left: 0px;
      margin-bottom: 12px;
    }
    position: absolute;
    right: 36px;
  }
}
.with-tooltip.copy-field::after {
  width: unset;
}
.blank {
  padding: 90px 0;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    display: flex;
  }
}

.shared-icon {
  padding: 0 0.35em 0 0.14em;
}
.button-bar-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  .icon {
    padding: 0 8px;
  }
}

.button-bar-item:hover {
  color: $orange;
  text-decoration: none !important;
}

.button-bar-item:hover img {
  filter: hue-rotate(170deg) brightness(215%) !important;
}

.button-bar-label {
  margin-left: 10px;
}

.retry-row {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 20px;
  width: fit-content;
  color: $blue;
  img {
    margin-right: 10px;
  }
}

.column-center-cross-axis-alignment {
  display: flex;
  flex-direction: column;
  align-items: center;
}
