@import '../../../../styles/colors.scss';

.uploaded-files-empty {
  padding: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  background-color: $mainBackground;
  img {
    margin-right: 10px;
  }
}
.uploaded-files {
  padding-bottom: 10px;
}
.info-bar {
  margin-top: 20px;
  display: flex;
  align-items: center;
  > * {
    margin-right: 10px;
    font-size: 14px;
  }
}
.file-list {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  a, a:hover {
    text-decoration: none !important;
  } 
  .file-card {
    display: flex;
    flex-direction: column;
    width: 128px;
    height: 128px;
    background-color: white;
    border-radius: 5px;
    margin-right: 15px;
    border: 1px solid $line;
    cursor: pointer;
    overflow: hidden;
    .top-section {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: space-between;
      .left-section {
        .file-ext {
          color: $line;
          margin-left: 5px;
          margin-top: 10px;
          font-size: 48px;
        }
        .file-size {
          color: $slate;
          margin-top: 10px;
          margin-left: 9px;
          font-size: 12px;
        }
      }
      .right-section {
        img {
          margin-top: 12px;
          margin-right: 10px;
        }
      }
    }
    .bottom-section {
      height: 42px;
      padding: 5px 9px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: $mainBackground;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}