@import url('https://fonts.googleapis.com/css2?family=Material+Icons');

.carousel-wrapper {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.carousel-title {
  margin-left: 30px;
}
.carousel {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  overflow: visible !important;
  .slider {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  button {
    background-color: white;
    color: #004385;
    border-radius: 50%;
    $width: 35px;
    height: $width;
    width: $width;
    box-shadow: none;
    border: 1px solid #dedfe1;
    clip: auto;
  }
  .button-left {
    margin-left: -40px;
  }
  .button-right {
    margin-right: -40px;
    transform: scaleX(-1) !important;
  }
  .button-right:hover {
    transform: scaleX(-1.04) !important;
  }
}
