@import './src/styles/colors.scss';

.editable-number-outer-container {
  margin: 8px 0 0 20px;
  .editable-number {
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    img {
      margin: 0 10px 0 0;
    }
    .value {
      color: $dark;
    }
    .default-value {
      margin: 0 8px;
      color: $disabled;
    }
    img.edit-icon {
      margin: 8px 0 0 10px;
    }
  }
}
