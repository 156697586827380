@import '../../../styles/colors.scss';
@import '../../../styles/responsiveness.scss';




$rowBackgroundColor: $hover;
$rowTextColor: $dark;

.table-container {
  .react-loading-skeleton {
    margin: 10px 0;
    &:first-of-type {
      margin-top: 30px;
    }
  }
  .overestimate {
    background: $error;
  }
  .underestimate {
    background: $blue;
  }
  .accurate {
    background: $success;
  }
  .skill-row {
    display: flex;
    max-height: 48px;
    padding: 8px 10px;
    background-color: $rowBackgroundColor;
    color: $rowTextColor;
    align-items: center;
    margin-bottom: 19px;
    border-radius: 5px;
    border: transparent 2px solid;

    div.label {
      display: none;
    }

    &.highlight {
      border: $blue 2px solid;
    }

    &.skill-heading {
      color: $slate;
      background-color: transparent;
    }

    .skill-name {
      display: flex;
      align-items: center;
      flex: 4;
    }
    .skill-icon {
      min-width: 28px;
      height: 28px;
      color: white;
      border-radius: 50%;
      overflow: hidden;
      font-size: 12px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 19px;
    }

    .skill-awareness-score {
      flex: 1.3;
      text-align: center;
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
    .skill-awareness {
      flex: 4;
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
  }

  @include respond(laptop-strict) {
    .skill-row {
      flex-wrap: wrap;
      max-height: none;

      &.skill-heading {
        display: none;
      }

      .skill-name {
        flex: 50%;
      }

      .skill-awareness-score {
        flex: 25% !important;
        text-align: left !important;
        margin: 8px 0;
      }
      .skill-awareness {
        flex: 100%;
      }
      div.label {
        color: $slate;
        display: block;
      }
    }
  }


  @include respond(phone) {
    .skill-row {
      flex-wrap: wrap;
      max-height: none;

      &.skill-heading {
        display: none;
      }

      .skill-name {
        flex: 100%;
      }

      .skill-awareness-score {
        flex: 50% !important;
        text-align: left !important;
        margin: 8px 0;
      }
      .skill-awareness {
        flex: 100%;
      }
      div.label {
        color: $slate;
      }
    }
  }
}
