@import './src/styles/colors.scss';
@import './src/styles/scroll.scss';

.interview-question-list {
  width: 380px;
  min-width: 300px;
  height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid $line;
  @include subtle-scroll;

  img.icon {
    height: 1em;
  }

  h3 {
    background: $light-cloud;
    font-size: 18px;
    line-height: 26px;
    margin-left: 5px;
    &.additional-criteria-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      .add-section {
        color: $blue;
        cursor: pointer;
        margin: 6px 20px 0 0;
      }
    }
  }

  .add-section {
    .description {
      margin: 10px 0;
    }
  }

  .question-groups {
    margin: 0px 15px;
    color: $dark;
    .question-group {
      .skill-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .skill {
          padding: 10px 0px;
          font-weight: bold;
          line-height: 26px;
          flex: auto;
        }
        .right {
          display: flex;
        }
        .num-questions {
          display: flex;
          align-items: center;
          .value {
            font-weight: bold;
            margin: 0 1em 0 0.5em;
          }
        }
        .chevron-up,
        .chevron-down {
          cursor: pointer;
          padding: 5px;
        }
        .chevron-up:before,
        .chevron-down:before {
          content: '';
          border-style: solid;
          border-width: 2px 2px 0 0;
          border-color: $blue;
          width: 4px;
          height: 4px;
          display: inline-block;
          position: relative;
        }
        .chevron-up:before {
          transform: rotate(-45deg);
        }
        .chevron-down:before {
          transform: rotate(135deg);
          top: -3px;
        }

        .dnd-icon {
          margin-left: 10px;
          margin-right: 5px;
          visibility: hidden;
        }
        &:hover {
          .dnd-icon:not(.dnd-icon-disabled) {
            visibility: visible;
          }
        }
        &:hover {
          background: $mainBackground;
        }
        &.selected {
          background: $mainBackground;
          .question-title {
            font-weight: bold;
          }
        }
      }

      .skill-questions {
        max-height: 500px;
        transition: max-height 0.5s;
        overflow: hidden;
        &.collapsed {
          max-height: 0px;
        }
      }
      .question {
        background: $light-cloud;
        margin: 2px 0px;
        padding: 10px;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        .question-title {
          display: flex;
          align-items: center;
          vertical-align: middle;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .right {
          display: flex;
          .question-delete-button,
          .question-delete-button-filler {
            margin: 0 0 -5px 5px;
            height: 1em;
          }
        }

        .dnd-icon {
          margin-right: 5px;
          visibility: hidden;
        }
        &:hover {
          background: $mainBackground;
          .dnd-icon {
            visibility: visible;
          }
        }
        &.selected {
          background: $mainBackground;
          .question-title {
            font-weight: bold;
          }
        }
      }
    }
    hr {
      margin: 15px 0px 5px;
    }
  }

  .add-section {
    padding-left: 10px;
  }
}
