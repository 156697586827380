@import "../../../styles/colors.scss";
.test-results-content {
  color: $dark;
  .side-by-side {
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    .question-info {
      padding-left: 20px;
      width: calc(100% - 334px);
      border-left: 1px solid $line;
    }
  }
  .test-status-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    img {
      width: 26px;
      margin-bottom: 20px;
    }
  }
  .subject-scores-summary {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 20px;
  }
  .skill-resource-container.subject-resource{
    margin-top: 20px;
    margin-left: 0;
    h3 {
      margin-bottom: 10px;
    }
  }
  .test-tab-timeline {
    border-top: 1px solid $line;
    margin-top: 20px;
    padding-top: 20px;
  }
}

.business-information {
  width: 100%;
}
@media only screen and (max-width: 800px) {
  .test-header {
    font-size: 10px;
  }
  .test-results-content {
    .side-by-side {
    flex-direction: column;
      .question-info {
        padding-left: 0;
        width: 100%;
        border-left: none;
      }
    }
  }
}

@media only screen and (min-width: 1800px) {
  .test-question-details-wrapper {
    .question-details-heading {
      width: 100%;
    }
    .question-content-wrapper.mcq-test {
      display: flex;
      .question-side {
        display: flex;
        flex-direction: column;
        width: calc(50% - 15px);
        max-width: calc(50% - 15px);
      }
      .question-details-children {
        display: flex;
        flex-direction: column;
        width: 50%;
        max-width: 50%;
        margin-left: 15px;
      }
    }
  }
};