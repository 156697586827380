.integration-loader {
  text-align: center;
  width: 100%;
  span {
    margin-left: -15px;
  }
}

.integration-partner {
  margin: 15px 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  button {
    // position: absolute;
    min-width: 150px;
    margin-right: 10px;
  }
  img {
    height: 32px;
    min-width: 140px;
    object-position: left;
    object-fit: scale-down;
    margin-top: 2px;
    margin-right: 110px;
  }
}
